import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.results-container': {
        position: 'relative',
        minHeight: '800px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        background: theme.palette.background.dark,
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    '.ellipse-236': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '545px',
        height: '545px',
        position: 'absolute',
        left: '-421px',
        top: '397px',
        filter: 'blur(250px)',
    },
    '.ellipse-237': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '225px',
        height: '225px',
        position: 'absolute',
        // left: '1215px',
        right: '0px',
        top: '-71px',
        filter: 'blur(150px)',
    },
    '.frame-1908': {
        padding: '0px 24px 24px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%',
        height: '716px',
        position: 'relative',
    },
    '.frame-1909': {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.buttons-navigation': {
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        height: '20px',
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    '.arrows-left': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.back': {
        color: theme.palette.grayscale.white,
        textAlign: 'left',
        position: 'relative',
    },
    '.frame-1939': {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '1',
        position: 'relative',
    },
    '.frame-1911': {
        display: 'flex',
        flexDirection: 'row',
        gap: '0px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flex: '1',
        position: 'relative',
        overflow: 'hidden',
    },
    '.result-container-skeleton': {
        background: `${theme.palette.theme.accent.transparentLight} !important`,
        borderRadius: '4px !important',
        alignSelf: 'stretch !important',
        width: '100% !important',
        height: '100% !important',
    },
    tableText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '.frame-1910': {
        background: theme.palette.grayscale.grey4,
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        width: '300px',
        height: '100%',
        position: 'absolute',
        zIndex: '3',
        top: '0',
        right: '0',
        overflowY: 'scroll',
        border: `1px solid ${theme.palette.grayscale.grey3}`,
        // Customize webkit scrollbar and scrollbar
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.grayscale.grey1} transparent`,
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grayscale.grey1,
            borderRadius: '500px',
            border: `3px solid ${theme.palette.background.dark}`,
        },
    },
    '.frame-1921': {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.frame-1912': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flex: '1',
        position: 'relative',
    },
    '.score-container': {
        textAlign: 'left',
        position: 'relative',
        flex: '1',
        width: '48px',
    },
    '.score': {
        color: theme.palette.grayscale.white,
    },
    '.total-score': {
        color: theme.palette.grayscale.grey2,
    },
    '.name': {
        display: 'flex',
        gap: '8px',
        color: theme.palette.grayscale.white,
        fontWeight: '700 !important',
        textAlign: 'left',
        position: 'relative',
        wordBreak: 'break-word',
        paddingBottom: '8px',
    },
    '.actions-close': {
        position: 'absolute',
        top: '0px',
        right: '0px',
        flexShrink: '0',
        overflow: 'visible',
        cursor: 'pointer',
        zIndex: '3',
    },
    '.attributes': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.attribute-container': {
        background: theme.palette.grayscale.black,
        borderRadius: '4px',
        padding: '12px 16px 12px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.attribute-row': {
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.indicators-check': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.check-attribute-name': {
        color: theme.palette.theme.abeil39,
        textAlign: 'left',
        position: 'relative',
        flex: '1',
    },
    '.comment-row': {
        padding: '0px 0px 0px 20px',
        display: 'flex',
        flexDirection: 'row',
        gap: '0px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.indicators-dash': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.dash-attribute-name': {
        color: theme.palette.grayscale.grey2,
        textAlign: 'left',
        position: 'relative',
        flex: '1',
    },
    '.comment': {
        color: theme.palette.grayscale.white,
        textAlign: 'left',
        position: 'relative',
        flex: '1',
    },

    '.frame-1869': {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '91px',
        height: '450px',
        position: 'relative',
    },
    '.processing-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '40px',
        width: '100%',
        height: '100%',
    },
    '.fluent-mdl-2-processing': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.frame-1931': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        position: 'relative',
    },
    '.data-process-may-take-up-to-20-mins': {
        color: theme.palette.grayscale.grey1,
        textAlign: 'center',
        position: 'relative',
    },
    '.frame-1932': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        position: 'relative',
    },
    '.social-media-email': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.we-will-notify-you-through-email-once-the-results-are-ready': {
        color: theme.palette.grayscale.white,
        textAlign: 'center',
        position: 'relative',
    },
}));

export default useStyles;
