// React / Other libraries
import * as React from 'react';
import { DateTime } from 'luxon';

// Firebase
import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInAnonymously,
    signInWithEmailAndPassword,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from 'firebase/auth';
import { auth } from '../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import { firebaseLogin } from '../api/auth';

// Context
import { SnackbarDispatchContext } from './Snackbar';
import { SidebarDispatchContext } from './SideBar';

// Utils
// import { isUserProviderPassword } from '../utils/firebase_utils';
// import { postVerificationEmailRecord, shouldSendVerificationEmail } from '../controllers/amuro_services';

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const UserContext = React.createContext(undefined);
const UserDispatchContext = React.createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function UserProvider({ children }) {
    const [loading, setLoading] = React.useState(true);
    const [userDetails, setUserDetails] = React.useState({});
    const [userSignUpInfo, setUserSignUpInfo] = React.useState({});
    const { enqueueSnackbar } = React.useContext(SnackbarDispatchContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        const authStateListener = auth.onAuthStateChanged(async (firebaseUser) => {
            try {
                if (firebaseUser) {
                    let firebaseJwt = await firebaseUser.getIdToken();
                    let result = await firebaseLogin(firebaseJwt, userSignUpInfo);
                    let userMetadata = {};

                    console.log('firebaseUser: ', firebaseUser);
                    console.log('result: ', result);

                    if (result?.user?.provider) {
                        userMetadata.provider = result?.user?.provider;
                    }

                    if (result?.user?.company_name) {
                        userMetadata.company_name = result?.user?.company_name;
                    }

                    if (result?.user?.phone_number) {
                        userMetadata.phone_number = result?.user?.phone_number;
                    }

                    setUserDetails({
                        ...userDetails,
                        firebaseUser: firebaseUser,
                        userMetadata: userMetadata,
                    });

                    setLoading(false);
                } else {
                    console.log(`No firebase user`);
                    if (window.location.pathname.includes('/corp')) {
                        navigate('/corp');
                    } else if (window.location.pathname.includes('/user')) {
                        navigate('/');
                    }

                    setLoading(false);
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        });
        return () => {
            authStateListener();
        };
    }, [userSignUpInfo]);

    const signUpWithEmail = async (email, password, options = {}, callback) => {
        try {
            let userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            let userMetadata = {};
            if (options.company_name) {
                userMetadata.company_name = options.company_name;
            }
            if (options.phone_number) {
                userMetadata.phone_number = options.phone_number;
            }

            setUserSignUpInfo(options);
            callback();
            navigate('/corp/cv-analysis');
        } catch (err) {
            console.log(err);
            switch (err.code) {
                case 'auth/email-already-in-use':
                    enqueueSnackbar('warning', 'Email is already used. Please try with another email.');
                    break;
                default:
                    enqueueSnackbar('warning', err.message);
                    break;
            }
            callback();
        }
    };

    const signInWithEmail = async (email, password, callback) => {
        try {
            let userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            callback();
        } catch (err) {
            console.log(err);
            switch (err.code) {
                case 'auth/user-not-found':
                    enqueueSnackbar('warning', 'User not found.');
                    break;
                case 'auth/wrong-password':
                    enqueueSnackbar('warning', 'Incorrect password.');
                    break;
                default:
                    enqueueSnackbar('warning', err.message);
                    break;
            }
            callback();
        }
    };

    const signInCorporate = async () => {
        try {
            let userCredential = await signInAnonymously(auth);
        } catch (err) {
            console.log(err);
            switch (err.code) {
                default:
                    enqueueSnackbar('warning', err.message);
                    break;
            }
        }
    };

    const logOut = async () => {
        if (userDetails.firebaseUser) {
            await auth.signOut();
        }
        setUserDetails({
            ...userDetails,
            firebaseUser: null,
            userMetadata: null,
        });
        setUserSignUpInfo({});
        navigate('/');
    };

    // const sendResetPasswordEmail = async (email, setCurrentPage) => {
    //     try {
    //         let result = await sendPasswordResetEmail(User, email);
    //         enqueueSnackbar('success', 'Reset password email has been sent!');
    //         setCurrentPage('signIn');
    //     } catch (err) {
    //         console.log(err);
    //         enqueueSnackbar('warning', err.message);
    //     }
    // };

    const setUpRecaptcha = (number) => {
        const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
        recaptchaVerifier.render();
        return signInWithPhoneNumber(auth, number, recaptchaVerifier);
    };

    return (
        <UserContext.Provider
            value={{
                loading,
                userDetails,
                logOut,
                signUpWithEmail,
                signInWithEmail,
                signInCorporate,
                setUpRecaptcha,
                // sendVerificationEmail,
                // handleVerification,
                // sendResetPasswordEmail,
            }}
        >
            <UserDispatchContext.Provider value={setUserDetails}>{children}</UserDispatchContext.Provider>
        </UserContext.Provider>
    );
}

export { UserProvider, UserContext, UserDispatchContext };
