// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// Context / API
import { getAnalysisRequests } from '../api/cvAnalysisRequest';
import { UserContext } from '../context/UserProvider';

const useResultList = () => {
    const navigate = useNavigate();
    const { userDetails } = React.useContext(UserContext);
    const [loading, setLoading] = React.useState(false);
    const [resultList, setResultList] = React.useState([]);
    const [sortOrder, setSortOrder] = React.useState('desc');

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                let response = await getAnalysisRequests(await userDetails.firebaseUser.getIdToken());
                setResultList(response);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.log(err);
            }
        })();
        return () => {
            // demount
        };
    }, []);

    // Sort by date in asc / desc order
    const handleSortByDate = () => {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
        setResultList([...resultList].reverse());
    };

    return {
        loading,
        resultList,
        setResultList,
        sortOrder,
        handleSortByDate,
    };
};

export default useResultList;
