// React / other libraries
import * as React from 'react';

// UI / Components
import useStyles from './styles';
import FilledButton from '../Buttons/FilledButton';
import { Box, Typography } from '@mui/material';

const PricingContainer = ({ isMostPopular = false, quota, pricing, jobDescriptionCount, cvCount, buttonLabel = 'Get This' }) => {
    const styles = useStyles();

    return (
        <Box className={`${styles['.pricing-container']}`}>
            <Box className={`${styles['.frame-1946']}`}>
                <Typography variant='desktop_bodyBold' className={`${styles['.quota']}`}>{`${quota}`}</Typography>
                <Typography variant='desktop_sidekick' className={`${styles['.pricing']}`}>{`${pricing}`}</Typography>
            </Box>
            <Box className={`${styles['.frame-1935']}`}>
                <Typography variant='desktop_footnote' className={`${styles['.each-quota-includes']}`}>
                    {'Each Quota Includes'}
                </Typography>
                <Box className={`${styles['.frame-1948']}`}>
                    <Typography
                        variant='desktop_body'
                        className={`${styles['.job-description-count']}`}
                    >{`${jobDescriptionCount}`}</Typography>
                    <Typography variant='desktop_body' className={`${styles['.cv-count']}`}>{`${cvCount}`}</Typography>
                </Box>
            </Box>
            {isMostPopular ? (
                <FilledButton buttonType='primary'>
                    <Typography variant='desktop_buttonPrimary'>{`${buttonLabel}`}</Typography>
                </FilledButton>
            ) : (
                <FilledButton buttonType='secondary'>
                    <Typography variant='desktop_buttonPrimary'>{`${buttonLabel}`}</Typography>
                </FilledButton>
            )}
            {isMostPopular && (
                <Box className={`${styles['.frame-1947']}`}>
                    <Typography variant='desktop_body' className={`${styles['.most-popular']}`}>
                        {'Most Popular'}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default PricingContainer;
