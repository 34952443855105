import './App.css';
import { Routes, Route } from 'react-router-dom';
import React from 'react';

import { Alert, Snackbar, Box, CircularProgress, Container, Typography } from '@mui/material';
import 'react-chat-elements/dist/main.css';
import { UserContext } from './context/UserProvider';
import { useTheme } from '@mui/styles';
import TopUp from './pages/TopUp/TopUp';
import ResultList from './pages/ResultList/ResultList';
import CreateAnalysisRequest from './pages/CVAnalysis/CreateAnalysisRequest';
import SignIn from './pages/PhoneAuthSignIn/PhoneAuthSignIn';
import CorpSignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import ResultDetail from './pages/ResultDetail/ResultDetail';
import Landing from './pages/Landing/Landing';
import SideBar from './components/SideBar/SideBar';

import { SnackbarContext } from './context/Snackbar';

function App() {
    const { userDetails, loading } = React.useContext(UserContext);
    const theme = useTheme();
    const { showSnackbar, snackbarContent, handleClose } = React.useContext(SnackbarContext);

    return (
        <Container
            maxWidth='false'
            style={{
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <SideBar />
            {loading ? (
                <Box
                    sx={{
                        minHeight: '800px',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.palette.background.dark,
                    }}
                >
                    <CircularProgress size={100} color='primary' />
                </Box>
            ) : (
                <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/corp' element={<CorpSignIn />} />
                    <Route path='/corp/sign-up' element={<SignUp />} />
                    <Route path='/user' element={<SignIn />} />
                    <Route path='/corp/result' element={<ResultList />} />
                    <Route path='/corp/result/:requestId' element={<ResultDetail />} />
                    <Route path='/corp/cv-analysis' element={<CreateAnalysisRequest cvUploadLimit={200} />} />
                    <Route path='/corp/top-up' element={<TopUp />} />
                    <Route path='/user/result' element={<ResultList />} />
                    <Route path='/user/result/:requestId' element={<ResultDetail />} />
                    <Route path='/user/cv-analysis' element={<CreateAnalysisRequest cvUploadLimit={1} />} />
                    <Route path='/user/top-up' element={<TopUp />} />
                    <Route path='*' element={<Typography variant='desktop_sidekick'>Page Not Found</Typography>} />
                </Routes>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showSnackbar}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                {snackbarContent.status ? (
                    snackbarContent.status === 'success' ? (
                        <Alert severity='success'>{snackbarContent.message}</Alert>
                    ) : (
                        <Alert severity='error'>{snackbarContent.message}</Alert>
                    )
                ) : null}
            </Snackbar>
        </Container>
    );
    // }
}

export default App;
