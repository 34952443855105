import * as React from 'react';

// material-ui
import { Box, Typography, Select, FormControl, MenuItem } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

const CustomizedSelect = (props) => {
    const theme = useTheme();

    const darkStyle = {
        height: '200px',
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.grayscale.white,
        '& .MuiMenuItem-root:hover': {
            background: theme.palette.grayscale.grey4 + ' !important',
        },
        '& .Mui-selected': {
            background: theme.palette.grayscale.grey4 + ' !important',
        },
    };

    const lightStyle = {
        height: '200px',
    };

    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', width: props.width, justifyContent: 'start', marginBottom: props.marginBottom }}
        >
            {props.label && (
                <Typography
                    variant='desktop_label'
                    component='div'
                    sx={{
                        marginBottom: '4px',
                        color: props.labelColor ? props.labelColor : theme.palette.grayscale.grey1,
                    }}
                >
                    {props.label}
                </Typography>
            )}
            <FormControl
                sx={{
                    width: '100%',
                    '& .MuiSvgIcon-root': {
                        color: !props.lightTheme ? theme.palette.grayscale.white : theme.palette.grayscale.grey2,
                    },
                    '& .MuiInputBase-root': {
                        borderRadius: '12px',
                        height: props.height,
                        color: props.textColor ? props.textColor : theme.palette.grayscale.white,
                        backgroundColor: props.backgroundColor ? props.backgroundColor : 'inherit',
                        '& fieldset': {
                            border: 'none',
                        },
                    },
                }}
            >
                <Select
                    inputProps={{
                        classes: {
                            icon: {
                                color: props.lightTheme ? theme.palette.grayscale.grey2 : theme.palette.grayscale.white,
                            },
                        },
                    }}
                    MenuProps={{
                        PaperProps: { sx: props.lightTheme ? lightStyle : darkStyle },
                    }}
                    startAdornment={props.startAdornment}
                    value={props.value}
                    onChange={props.onChange}
                    defaultValue='file'
                    {...props.others}
                >
                    <MenuItem disabled value='none'>
                        <Typography variant='desktop_body' color={theme.palette.grayscale.grey2}>
                            {props.placeholder}
                        </Typography>
                    </MenuItem>
                    {props.children}
                </Select>
            </FormControl>
        </Box>
    );
};

export default CustomizedSelect;
