import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.full-width-container': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'center',
        minHeight: '100vh',
        background: theme.palette.background.dark,
        [theme.breakpoints.down('sm')]: {
            minWidth: '375px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100% !important',
        },
    },
    '.content-container': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 0px 200px 0px!important',
        [theme.breakpoints.down('sm')]: {
            width: '375px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100% !important',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: '100%!important',
        },
        [theme.breakpoints.up('lg')]: {
            width: '1440px !important',
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '800px',
        },
        [theme.breakpoints.down('md')]: {
            minHeight: '800px',
        },
    },
    '.ellipse-236': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '545px',
        height: '545px',
        position: 'absolute',
        left: '-247px',
        top: '1365px',
        filter: 'blur(250px)',
    },
    '.ellipse-2362': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '460px',
        height: '460px',
        position: 'absolute',
        left: '204px',
        top: '-230px',
        filter: 'blur(150px)',
    },
    '.frame-1804': {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '840px',
        margin: '175.5px 24px 0px 24px',
    },
    '.amuro-logo': {
        flexShrink: '0',
        width: '216px',
        height: '64px',
        position: 'relative',
    },
    '.logo-primary-red': {
        position: 'absolute',
        left: '16.57px',
        top: '14.16px',
        overflow: 'visible',
    },
    '.frame-1740': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    '.title': {
        fontFamily: 'Oxygen !important',
        fontSize: '32px !important',
        fontWeight: '400 !important',
        lineHeight: '38px !important',
        letterSpacing: '0px !important',
        textAlign: 'center',
        color: theme.palette.grayscale.white,
    },
    '.subtitle': {
        color: theme.palette.grayscale.white,
        textAlign: 'center',
        fontFamily: 'Oxygen !important',
        fontSize: '16px !important',
        fontWeight: '400 !important',
        lineHeight: '125% !important',
        position: 'relative',
        alignSelf: 'stretch',
        whiteSpace: 'pre-wrap',
    },
    '.frame-1966': {
        position: 'relative',
        width: '100%',
        height: '750px',
        marginTop: '120px',
        [theme.breakpoints.down('800')]: {
            alignSelf: 'stretch',
            flexShrink: '0',
            height: '298px',
            marginTop: '40px',
        },
        [theme.breakpoints.between('800', 'lg')]: {
            flexShrink: '0',
            width: '1109px',
            height: '496px',
        },
    },
    '.screenshot-1': {
        zIndex: '4',
        width: '693px',
        height: '385px',
        aspectRatio: '693 / 385',
        position: 'absolute',
        left: '-363px',
        top: '40px',
        [theme.breakpoints.down('800')]: {
            width: '236px',
            height: '131px',
            left: 'calc(50% - 253.5px)',
            top: 'calc(50% - 114px)',
        },
        [theme.breakpoints.between('800', 'lg')]: {
            width: '444px',
            height: '246px',
            left: 'calc(50% - 483.5px)',
            top: 'calc(50% - 214px)',
        },
    },
    '.screenshot-2': {
        zIndex: '5',
        width: '1048px',
        height: '582px',
        aspectRatio: '1048 / 582',
        position: 'absolute',
        left: '125px',
        top: '128px',
        [theme.breakpoints.down('800')]: {
            width: '357px',
            height: '198px',
            left: 'calc(50% - 87.5px)',
            top: 'calc(50% - 84px)',
        },
        [theme.breakpoints.between('800', 'lg')]: {
            width: '671px',
            height: '372px',
            left: 'calc(50% - 171.5px)',
            top: 'calc(50% - 158px)',
        },
    },
    '.frame-1794': {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '115.5px',
    },
    '.frame-1797': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: '0',
        position: 'relative',
        width: '100%',
    },
    '.companies-we-ve-worked-with': {
        color: theme.palette.grayscale.grey1,
        textAlign: 'center',
        position: 'relative',
    },
    '.frame-1792': {
        display: 'flex',
        flexDirection: 'row',
        gap: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: '0',
        position: 'relative',
        width: '100%',
        [theme.breakpoints.down('800')]: {
            gap: '8px',
            flexWrap: 'wrap',
        },
    },
    '.sino-logo': {
        flexShrink: '0',
        width: '85px',
        height: '20px',
        position: 'relative',
    },
    '.hkstp-logo': {
        flexShrink: '0',
        width: '81px',
        height: '42px',
        position: 'relative',
    },
    '.swire-logo': {
        flexShrink: '0',
        width: '79px',
        height: '22px',
        position: 'relative',
        overflow: 'hidden',
    },
    '.cathay-logo': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.frame-1796': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.frame-1795': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        position: 'relative',
    },
    '.email': {
        color: theme.palette.grayscale.grey1,
        textAlign: 'center',
    },
    '.support-amuro-io': {
        color: theme.palette.grayscale.white,
        textAlign: 'center',
    },
    '.group-631': {
        position: 'absolute',
        inset: '0',
    },
    '.rectangle-7571': {
        borderStyle: 'solid',
        borderColor: 'var(--grayscale-grey-3, #585a67)',
        borderWidth: '1px',
        width: '604.57px',
        height: '604.57px',
        position: 'absolute',
        left: '-82.87px',
        top: '2273.5px',
        transformOrigin: '0 0',
        transform: 'rotate(31.694deg) scale(1, 1)',
    },
    '.rectangle-7572': {
        borderStyle: 'solid',
        borderColor: 'var(--grayscale-grey-3, #585a67)',
        borderWidth: '1px',
        width: '604.57px',
        height: '604.57px',
        position: 'absolute',
        left: '28.16px',
        top: '2262.21px',
        transformOrigin: '0 0',
        transform: 'rotate(46.694deg) scale(1, 1)',
    },
    '.rectangle-7573': {
        borderStyle: 'solid',
        borderColor: 'var(--grayscale-grey-3, #585a67)',
        borderWidth: '1px',
        width: '358.86px',
        height: '358.86px',
        position: 'absolute',
        left: '-104.81px',
        top: '2466.11px',
        transformOrigin: '0 0',
        transform: 'rotate(16.694deg) scale(1, 1)',
    },
    '.rectangle-7574': {
        borderStyle: 'solid',
        borderColor: 'var(--grayscale-grey-3, #585a67)',
        borderWidth: '1px',
        width: '312.6px',
        height: '312.6px',
        position: 'absolute',
        left: '-89.3px',
        top: '2494.91px',
        transformOrigin: '0 0',
        transform: 'rotate(16.694deg) scale(1, 1)',
    },
    '.rectangle-7575': {
        borderStyle: 'solid',
        borderColor: 'var(--grayscale-grey-3, #585a67)',
        borderWidth: '1px',
        width: '147.82px',
        height: '147.82px',
        position: 'absolute',
        left: '29.93px',
        top: '2585.99px',
        transformOrigin: '0 0',
        transform: 'rotate(52.922deg) scale(1, 1)',
    },
    '.group-630': {
        position: 'absolute',
        left: '1048px',
        top: '255px',
        overflow: 'visible',
    },
    '.floating-request-access-button': {
        position: 'fixed !important',
        width: '90% !important',
        left: '5%',
        bottom: '5%',
        zIndex: '100',
    },
}));

export default useStyles;
