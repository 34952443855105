// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import shortid from 'shortid';

// MUI / Components
import { Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import CustomizedIconButton from '../Buttons/IconButton';

// Images
import { ReactComponent as CrossVector } from '../../assets/images/cross_icon.svg';
import useStyles from './styles';

// Context
import { SidebarContext, SidebarDispatchContext } from '../../context/SideBar';
import { UserContext } from '../../context/UserProvider';

const SideBar = ({}) => {
    // Context
    const { sidebarOpen } = React.useContext(SidebarContext);
    const { closeSidebar } = React.useContext(SidebarDispatchContext);
    const { userDetails, logOut } = React.useContext(UserContext);

    // UI
    const themedStyles = useStyles();
    const theme = useTheme();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    const navigate = useNavigate();

    let routePrefix = '/user';
    if (window.location.pathname.includes('/corp')) {
        routePrefix = '/corp';
    }

    const options = [
        {
            id: shortid.generate(),
            name: 'Home',
            callback: () => {
                closeSidebar();
                navigate('/');
            },
        },
        {
            id: shortid.generate(),
            name: 'Result',
            callback: () => {
                closeSidebar();
                navigate(`${routePrefix}/result`);
            },
        },
        {
            id: shortid.generate(),
            name: 'Screen CVs',
            callback: () => {
                closeSidebar();
                navigate(`${routePrefix}/cv-analysis`);
            },
        },
        {
            id: shortid.generate(),
            name: 'Top Up',
            callback: () => {
                closeSidebar();
                navigate(`${routePrefix}/top-up`);
            },
        },
        {
            id: shortid.generate(),
            name: 'Log out',
            callback: () => {
                closeSidebar();
                logOut();
            },
        },
    ];

    return (
        <>
            <Box
                className={`${themedStyles.sideBarWrapper} ${
                    sidebarOpen ? themedStyles.sideBarWrapperActive : themedStyles.sideBarWrapperNotActive
                }`}
            >
                <Box
                    onClick={() => {
                        if (sidebarOpen) {
                            closeSidebar();
                        }
                    }}
                    className={themedStyles.filter}
                ></Box>
                <Box className={themedStyles.sideBarContainer}>
                    <Box className={themedStyles.accountInfoContainer}>
                        <CustomizedIconButton
                            className={themedStyles.closeButton}
                            onClick={() => {
                                closeSidebar();
                            }}
                        >
                            <CrossVector fill={theme.palette.grayscale.white} />
                        </CustomizedIconButton>

                        <Box className={themedStyles.proPic}></Box>
                        <Box className={themedStyles.accountInfo}>
                            <Typography className={themedStyles.userConnectText} component='div' variant={'desktop_bodyBold'}>
                                {'User connected as'}
                            </Typography>

                            {userDetails.firebaseUser && (
                                /**
                                 * Firebase users - user display name
                                 */
                                <Box className={themedStyles.custodianWalletInfo}>
                                    {/* <CustomToolTip tooltipText={userDetails.firebaseUser.email || userDetails.firebaseUser.phoneNumber}> */}
                                    {userDetails.firebaseUser.isAnonymous ? (
                                        <Typography component='div' variant={'desktop_body'} className={themedStyles.custodianWalletText}>
                                            {`Anonymous User`}
                                        </Typography>
                                    ) : (
                                        <Typography component='div' variant={'desktop_body'} className={themedStyles.custodianWalletText}>
                                            {`${userDetails.firebaseUser.email || userDetails.firebaseUser.phoneNumber}`}
                                        </Typography>
                                    )}
                                    {/* </CustomToolTip> */}
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <ul className={themedStyles.options}>
                        {options.map((option, index) => {
                            return (
                                <li key={option.id} className={themedStyles.option}>
                                    {
                                        <Box onClick={option.callback}>
                                            <Typography variant={'desktop_buttonPrimary'}>{option.name}</Typography>
                                        </Box>
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </Box>
            </Box>

            {/* <SignOutDialog /> */}
        </>
    );
};

export default React.memo(SideBar, (prevProps, nextProps) => {
    return true;
});
