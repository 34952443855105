import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import theme from './theme';
import { UserProvider } from './context/UserProvider';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SidebarProvider } from './context/SideBar';
import { SnackbarProvider } from './context/Snackbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <RecoilRoot>
                    <SnackbarProvider>
                        <UserProvider>
                            <SidebarProvider>
                                <CssBaseline />
                                <App />
                            </SidebarProvider>
                        </UserProvider>
                    </SnackbarProvider>
                </RecoilRoot>
            </ThemeProvider>
        </BrowserRouter>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
