// React / other libraries
import * as React from 'react';

// Context
import { UserContext } from '../context/UserProvider';
import { SnackbarDispatchContext } from '../context/Snackbar';

const useSignUp = () => {
    const { enqueueSnackbar } = React.useContext(SnackbarDispatchContext);
    const [signUpForm, setSignUpForm] = React.useState({
        email: '',
        password: '',
        company_name: '',
        phone_number: '',
    });
    const [loading, setLoading] = React.useState(false);

    const { signUpWithEmail } = React.useContext(UserContext);

    const validateForm = () => {
        if (signUpForm.email === '') {
            enqueueSnackbar('warning', 'Email cannot be emptied.');
            return false;
        }

        if (signUpForm.password === '') {
            enqueueSnackbar('warning', 'Password cannot be emptied.');
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            let options = {};
            if (signUpForm.company_name != '') {
                options.company_name = signUpForm.company_name;
            }
            if (signUpForm.phone_number != '') {
                options.phone_number = signUpForm.phone_number;
            }
            signUpWithEmail(signUpForm.email, signUpForm.password, options, () => {
                setLoading(false);
            });
        }
    };

    return {
        loading,
        signUpForm,
        setSignUpForm,
        validateForm,
        handleSubmit,
    };
};

export default useSignUp;
