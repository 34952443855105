// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// Context / API
import { UserContext } from '../context/UserProvider';
import { SnackbarDispatchContext } from '../context/Snackbar';
import { createAnalysisRequest } from '../api/cvAnalysisRequest';

const useCreateAnalysisRequest = ({ cvUploadLimit = 1 }) => {
    const navigate = useNavigate();
    const { userDetails } = React.useContext(UserContext);
    const { enqueueSnackbar } = React.useContext(SnackbarDispatchContext);
    const [loading, setLoading] = React.useState(false);
    const initForm = {
        job_description_type: 'file',
        job_description_file: null,
        job_description_url: '',
        files: [],
    };

    const [createAnalysisRequestForm, setCreateAnalysisRequestForm] = React.useState({ ...initForm });
    const jobDescriptionFileInput = React.useRef(null);
    const CVFileInput = React.useRef(null);

    const jobDescriptionTypes = [
        {
            label: 'Upload Documents',
            value: 'file',
        },
        {
            label: 'Link - LinkedIn',
            value: 'linkedin',
        },
        {
            label: 'Link - JobsDB',
            value: 'jobsdb',
        },
        {
            label: 'Link - Others',
            value: 'others',
        },
    ];

    const handleSelectJobDescriptionType = (event) => {
        setCreateAnalysisRequestForm({
            ...createAnalysisRequestForm,
            job_description_type: event.target.value,
            job_description_file: null,
            job_description_url: '',
        });
    };

    const jobDescriptionUploadHandler = (event) => {
        setCreateAnalysisRequestForm({
            ...createAnalysisRequestForm,
            job_description_file: event.target.files[0],
        });
    };

    const CVUploadHandler = (event) => {
        // For jobseeker user, limit to 1 CV

        if ([...createAnalysisRequestForm.files, ...event.target.files].length <= cvUploadLimit) {
            setCreateAnalysisRequestForm({
                ...createAnalysisRequestForm,
                files: [...createAnalysisRequestForm.files, ...event.target.files],
            });
        } else {
            enqueueSnackbar('warning', 'Exceed Maximum Upload Limit');
        }
    };

    const removeJobDescriptionFileHandler = () => {
        setCreateAnalysisRequestForm({
            ...createAnalysisRequestForm,
            job_description_file: null,
        });
    };

    const removeCVFileHandler = (index) => {
        setCreateAnalysisRequestForm({
            ...createAnalysisRequestForm,
            files: createAnalysisRequestForm.files.filter((_, i) => i !== index),
        });
    };

    const validateForm = () => {
        if (createAnalysisRequestForm.job_description_type === 'file' && !createAnalysisRequestForm.job_description_file) {
            enqueueSnackbar('warning', 'Please upload a job description file');
            return false;
        }

        if (createAnalysisRequestForm.job_description_type != 'file' && createAnalysisRequestForm.job_description_url === '') {
            enqueueSnackbar('warning', 'Please enter a job description URL');
            return false;
        }

        if (createAnalysisRequestForm.files.length === 0) {
            enqueueSnackbar('warning', 'Please upload CVs');
            return false;
        }
        return true;
    };

    React.useEffect(() => {
        console.log(createAnalysisRequestForm);
    }, [createAnalysisRequestForm]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (validateForm()) {
                setLoading(true);
                let response = await createAnalysisRequest(
                    await userDetails.firebaseUser.getIdToken(),
                    null,
                    createAnalysisRequestForm.files,
                    createAnalysisRequestForm.job_description_type,
                    createAnalysisRequestForm.job_description_file,
                    createAnalysisRequestForm.job_description_url
                );

                if (response?.request_id) {
                    if (window.location.pathname.includes('/user')) {
                        navigate(`/user/result/${response.request_id}`);
                    } else if (window.location.pathname.includes('/corp')) {
                        navigate(`/corp/result/${response.request_id}`);
                    }
                    setLoading(false);
                }
                console.log(response);
            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar('warning', 'Error in creating analysis request');
            setLoading(false);
            setCreateAnalysisRequestForm({ ...initForm });
        }
    };
    return {
        loading,
        setLoading,
        createAnalysisRequestForm,
        setCreateAnalysisRequestForm,
        jobDescriptionFileInput,
        CVFileInput,
        jobDescriptionTypes,
        handleSelectJobDescriptionType,
        jobDescriptionUploadHandler,
        CVUploadHandler,
        removeJobDescriptionFileHandler,
        removeCVFileHandler,
        handleSubmit,
    };
};

export default useCreateAnalysisRequest;
