// React / other libraries
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// MUI / Components
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Navigation from '../Navigation/Navigation';
import useStyles from './styles';
import CustomizedIconButton from '../Buttons/IconButton';

// Images
import { ReactComponent as AmuroLogo } from '../../assets/images/amuro_logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu_icon.svg';

// Context
import { UserContext } from '../../context/UserProvider';
import { SidebarDispatchContext } from '../../context/SideBar';
import FilledButton from '../Buttons/FilledButton';
import { windowNavigate } from '../../utils/utils';

const NavBar = ({ isJobseeker = false, setIsJobseeker }) => {
    const theme = useTheme();
    const styles = useStyles();
    const { userDetails } = React.useContext(UserContext);
    const { openSidebar } = React.useContext(SidebarDispatchContext);
    const isBelow700px = useMediaQuery(theme.breakpoints.down('700'));
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = React.useState(false);

    return (
        <Box className={`${styles['.nav-bar']}`}>
            <Link to='/'>
                <AmuroLogo className={`${styles['.nav-bar-logo']}`} />
            </Link>

            {
                <Box className={`${styles['.frame-209']}`}>
                    {isJobseeker ? (
                        <>
                            <Typography
                                className={`${styles['.switch']}`}
                                onClick={() => setIsJobseeker((prev) => !prev)}
                            >{`I Hire People`}</Typography>
                            <FilledButton buttonType='primary' onClick={() => navigate('/user')}>
                                <Typography variant='desktop_buttonPrimary'>{`Sign In`}</Typography>
                            </FilledButton>
                        </>
                    ) : (
                        <>
                            <Typography
                                className={`${styles['.switch']}`}
                                onClick={() => setIsJobseeker((prev) => !prev)}
                            >{`I'm a Job Seeker`}</Typography>
                            <FilledButton buttonType='primary' onClick={() => navigate('/corp')}>
                                <Typography variant='desktop_buttonPrimary'>{`Sign In`}</Typography>
                            </FilledButton>
                        </>
                    )}
                </Box>
            }
        </Box>
    );
};

export default NavBar;
