import React from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { useMediaQuery, useTheme } from '@mui/material';
import useStyles from './styles';

const FilledButton = (props) => {
    const theme = useTheme();
    const styles = useStyles();
    const isMobileForUI = useMediaQuery(theme.breakpoints.down('md'));

    const getClassName = () => {
        let className = props.className + ' ' ?? '';

        switch (props?.buttonType) {
            case 'primary':
                return (className += styles['.buttons-primary']);
                break;
            case 'secondary':
                return (className += styles['.buttons-secondary']);
                break;
        }
    };

    return (
        <Button
            variant='contained'
            sx={{
                width: props.width ? props.width : 'auto',
                height: props.height ? props.height : isMobileForUI ? '44px' : '46px',
                padding: props.padding ? props.padding : '12 32px !important',
                background: props.color ? props.color : theme.palette.secondary.blue700,
                color: props.textColor ? props.textColor : theme.palette.grayscale.grayWhite,
                borderRadius: props.borderRadius ? props.borderRadius : '4px !important',
                '& > *': {
                    textTransform: 'none',
                },
                transition: 'background 0.5s',
                '&:disabled': {
                    color: theme.palette.grayscale.grey2,
                    background: theme.palette.grayscale.grey3,
                },
                '&:hover': {
                    background: props.hoverColor ? props.hoverColor : theme.palette.secondary.main,
                },
                marginTop: props.marginTop,
                marginBottom: props.marginBottom,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                margin: props.margin,
            }}
            className={getClassName()}
            onClick={props.onClick}
            disabled={props.disabled}
            {...props.others}
        >
            {props.children}
        </Button>
    );
};

export default React.memo(FilledButton, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
