import * as React from 'react';

// Create two context:
// SnackbarContext: to query the context state
// SnackbarDispatchContext: to mutate the context state
const SnackbarContext = React.createContext(undefined);
const SnackbarDispatchContext = React.createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function SnackbarProvider({ children }) {
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarContent, setSnackbarContent] = React.useState({
        status: '',
        message: '',
    });

    const handleClose = (event, reason) => {
        setSnackbarContent({
            status: '',
            message: '',
        });
        setShowSnackbar(false);
        if (reason === 'clickaway') {
            return;
        }
    };

    const enqueueSnackbar = (status, message) => {
        setSnackbarContent({
            status: status,
            message: message,
        });
        setShowSnackbar(true);
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar, snackbarContent, handleClose }}>
            <SnackbarDispatchContext.Provider value={{ enqueueSnackbar }}>{children}</SnackbarDispatchContext.Provider>
        </SnackbarContext.Provider>
    );
}

export { SnackbarProvider, SnackbarContext, SnackbarDispatchContext };
