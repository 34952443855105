import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.create-analysis-container': {
        minHeight: '800px',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '96px',
        background: theme.palette.background.dark,
    },
    '.create-analysis-form': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '40px',
    },
    '.form-group': {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    '.jd-container': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '80%',
        maxWidth: '400px',
        marginTop: '16px',
        '& > div': {
            dispaly: 'flex',
            justifyContent: 'space-between',
            minHeight: '40px',
            width: '100%',
            borderRadius: '4px',
            background: theme.palette.grayscale.grey4,
        },
    },
    '.jd-upload-container': {
        padding: '10px 16px',
    },
    '.jd-placeholder': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '.cv-container': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        width: '80%',
        maxWidth: '400px',
        marginTop: '16px',
        '& > div': {
            dispaly: 'flex',
            flexDirection: 'column',
            minHeight: '40px',
            width: '100%',
            padding: '10px 16px',
            borderRadius: '4px',
            background: theme.palette.grayscale.grey4,
        },
    },
    '.cv-placeholder': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '.files-container': {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        overflowY: 'auto',
        marginTop: '8px',
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.grayscale.grey1} transparent`,
        '&::-webkit-scrollbar': {
            backgroundColor: theme.palette.grayscale.grey4,
            width: '12px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: theme.palette.grayscale.grey4,
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grayscale.grey1,
            borderRadius: '500px',
            border: `3px solid ${theme.palette.grayscale.grey4}`,
        },
    },
    '.file-container': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '4px 8px',
        borderRadius: '4px',
        background: theme.palette.theme.accent.transparentLight,
    },
    '.upload-icon': {
        cursor: 'pointer',
        flexShrink: '0',
    },
    '.cross-icon': {
        cursor: 'pointer',
        width: '8px',
        flexShrink: '0',
    },
}));

export default useStyles;
