import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.results-container': {
        position: 'relative',
        minHeight: '800px',
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        background: theme.palette.background.dark,
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    '.ellipse-236': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '545px',
        height: '545px',
        position: 'absolute',
        left: '-421px',
        top: '397px',
        filter: 'blur(250px)',
    },
    '.ellipse-237': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '225px',
        height: '225px',
        position: 'absolute',
        // left: '1215px',
        right: '0px',
        top: '-71px',
        filter: 'blur(150px)',
    },
    '.frame-1944': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '0px',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: '160px',
    },
    '.frame-1869': {
        position: 'relative',
        maxWidth: '500px',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    '.frame-1901': {
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
    },
    '.action-delete': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.frame-1945': {
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '1',
        position: 'relative',
    },
    '.sort-by-last-saved': {
        color: theme.palette.grayscale.white,
        textAlign: 'left',
        position: 'relative',
    },
    '.sort-desc': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.sort-asc': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
        transform: 'rotate(180deg)',
    },
    '.frame-1873': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        flexShrink: '0',
        position: 'relative',
        height: '264px',
        overflowY: 'scroll',
        // Customize webkit scrollbar and scrollbar
        scrollbarWidth: 'thin',
        scrollbarColor: `${theme.palette.grayscale.grey1} transparent`,
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            background: theme.palette.grayscale.grey1,
            borderRadius: '500px',
            border: `3px solid ${theme.palette.background.dark}`,
        },
    },
    '.result-container-skeleton': {
        background: `${theme.palette.theme.accent.transparentLight} !important`,
        borderRadius: '4px !important',
        padding: '8px 12px 8px 16px !important',
        alignSelf: 'stretch !important',
        flexShrink: '0 !important',
        height: '40px !important',
    },
    '.result-container': {
        background: theme.palette.theme.accent.transparentLight,
        borderRadius: '4px',
        padding: '8px 12px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'stretch',
        flexShrink: '0',
        minHeight: '40px',
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        '& > div:nth-child(2)': {
            display: 'flex',
            flexShrink: '0',
        },
    },
    '.jd-file-name-50': {
        textAlign: 'left',
        position: 'relative',
        width: '70%',
        '& > span:nth-child(1)': {
            wordBreak: 'break-all',
            color: theme.palette.grayscale.white,
        },
        '& > span:nth-child(2)': {
            color: theme.palette.grayscale.grey2,
        },
    },

    '.date': {
        color: theme.palette.grayscale.white,
        textAlign: 'right',
        position: 'relative',
    },
    '.arrows-right': {
        flexShrink: '0',
        position: 'relative',
        overflow: 'visible',
    },
    '.no-result-container': {
        display: 'flex',
        flexDirection: 'row',
        height: '200px',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        flexShrink: '0',
    },
    '.no-result': {
        color: theme.palette.grayscale.white,
    },
}));

export default useStyles;
