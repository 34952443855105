import axios from 'axios';
import _ from 'lodash';
import * as AmuroNetworkController from '../controller/amuro_network_controller';

export const createAnalysisRequest = async (firebaseJwt, companyName, files, jobDescriptionType, jobDescriptionFile, jobDescriptionUrl) => {
    let formData = new FormData();
    formData.append('company_name', companyName);
    formData.append('chunk_size_limit', 4096);
    formData.append('job_description_type', jobDescriptionType);
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
    }
    if (jobDescriptionType === 'file') {
        formData.append('job_description_file', jobDescriptionFile);
    } else {
        formData.append('job_description_url', jobDescriptionUrl);
    }
    let headers = {
        'Content-Type': 'multipart/form-data',
    };
    return AmuroNetworkController.post(
        '/web/chatbot/create-cv-analysis-request',
        formData,
        {
            headers: AmuroNetworkController.insertCredentialsIntoHeaders(headers, firebaseJwt, null),
        },
        true
    );
};

export const getAnalysisRequests = async (firebaseJwt, options = {}) => {
    let queryParams = {};
    if (options.requestId) {
        queryParams.request_id = options.requestId;
    }

    return AmuroNetworkController.get(`/web/chatbot/cv-analysis-requests`, queryParams, {
        headers: AmuroNetworkController.insertCredentialsIntoHeaders({}, firebaseJwt, null),
    });
};

export const starCv = async (firebaseJwt, cvExtId, starred) => {
    return AmuroNetworkController.post(
        `/web/chatbot/star-cv`,
        {
            cv_id: cvExtId,
            starred: starred,
        },
        {
            headers: AmuroNetworkController.insertCredentialsIntoHeaders({}, firebaseJwt, null),
        }
    );
};

export const getAnalysisRequest = async (firebaseJwt, requestId) => {};
