import axios from 'axios';

export const insertCredentialsIntoHeaders = (headers, firebaseJwt, walletCredentials) => {
    if (firebaseJwt) {
        headers['authorization'] = 'Bearer ' + firebaseJwt;
    }
    if (walletCredentials) {
        headers['wallet-credentials'] = walletCredentials;
    }
    return headers;
};

export const get = async (path, queryParams, options, throwError = false) => {
    try {
        let params;
        if (queryParams) {
            params = new URLSearchParams(queryParams);
        } else {
            params = new URLSearchParams({});
        }
        let response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${path}?${params.toString()}`, options);
        let responseData = response.data;
        if (!responseData) {
            console.log(`No data found in response: ${JSON.stringify(response)}`);
            return null;
        }
        return responseData;
    } catch (err) {
        if (throwError) {
            throw err;
        }
        return err.response.data;
    }
};

export const post = async (path, body, options, throwError = false) => {
    try {
        let response = await axios.post(`${process.env.REACT_APP_SERVER_HOST}${path}`, body, options);
        let responseData = response.data;
        if (!responseData) {
            console.log(`No data found in response: ${JSON.stringify(response)}`);
            return null;
        }
        return responseData;
    } catch (err) {
        console.log(err);
        if (throwError) {
            throw err;
        }
        return err.response.data;
    }
};
