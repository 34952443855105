import { Box, Typography, useTheme } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './dark_style.css';

const ShortCodeToCountry = {
    af: 'Afghanistan',
    al: 'Albania',
    dz: 'Algeria',
    ad: 'Andorra',
    ao: 'Angola',
    ag: 'Antigua and Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    aw: 'Aruba',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaijan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgium',
    bz: 'Belize',
    bj: 'Benin',
    bt: 'Bhutan',
    bo: 'Bolivia',
    ba: 'Bosnia and Herzegovina',
    bw: 'Botswana',
    br: 'Brazil',
    io: 'British Indian Ocean Territory',
    bn: 'Brunei Darussalam',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodia',
    cm: 'Cameroon',
    ca: 'Canada',
    cv: 'Cape Verde',
    bq: 'Bonaire',
    cf: 'Central African Republic',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    co: 'Colombia',
    km: 'Comoros',
    cd: 'Congo',
    cg: 'Congo',
    cr: 'Costa Rica',
    ci: "Côte d'Ivoire",
    hr: 'Croatia',
    cu: 'Cuba',
    cw: 'Curaçao',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    dk: 'Denmark',
    dj: 'Djibouti',
    dm: 'Dominica',
    do: 'Dominican Republic',
    ec: 'Ecuador',
    eg: 'Egypt',
    sv: 'El Salvador',
    gq: 'Equatorial Guinea',
    er: 'Eritrea',
    ee: 'Estonia',
    et: 'Ethiopia',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'France',
    gf: 'French Guiana',
    pf: 'French Polynesia',
    ga: 'Gabon',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gr: 'Greece',
    gd: 'Grenada',
    gp: 'Guadeloupe',
    gu: 'Guam',
    gt: 'Guatemala',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hn: 'Honduras',
    hk: 'Hong Kong',
    hu: 'Hungary',
    is: 'Iceland',
    in: 'India',
    id: 'Indonesia',
    ir: 'Iran',
    iq: 'Iraq',
    ie: 'Ireland',
    il: 'Israel',
    it: 'Italy',
    jm: 'Jamaica',
    jp: 'Japan',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    xk: 'Kosovo',
    kw: 'Kuwait',
    kg: 'Kyrgyzstan',
    la: 'Laos',
    lv: 'Latvia',
    lb: 'Lebanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libya',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mo: 'Macao',
    mk: 'Macedonia',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall Islands',
    mq: 'Martinique',
    mr: 'Mauritania',
    mu: 'Mauritius',
    mx: 'Mexico',
    fm: 'Micronesia',
    md: 'Moldova',
    mc: 'Monaco',
    mn: 'Mongolia',
    me: 'Montenegro',
    ma: 'Morocco',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Netherlands',
    nc: 'New Caledonia',
    nz: 'New Zealand',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    kp: 'Korea',
    no: 'Norway',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palestine',
    pa: 'Panama',
    pg: 'Papua New Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippines',
    pl: 'Poland',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Qatar',
    re: 'Réunion',
    ro: 'Romania',
    ru: 'Russian Federation',
    rw: 'Rwanda',
    kn: 'Saint Kitts and Nevis',
    lc: 'Saint Lucia',
    vc: 'Saint Vincent and the Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tome and Principe',
    sa: 'Saudi Arabia',
    sn: 'Senegal',
    rs: 'Serbia',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sk: 'Slovakia',
    si: 'Slovenia',
    sb: 'Solomon Islands',
    so: 'Somalia',
    za: 'South Africa',
    kr: 'Korea',
    ss: 'South Sudan',
    es: 'Spain',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    sz: 'Eswatini',
    se: 'Sweden',
    ch: 'Switzerland',
    sy: 'Syrian Arab Republic',
    tw: 'Taiwan',
    tj: 'Tajikistan',
    tz: 'Tanzania',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    to: 'Tonga',
    tt: 'Trinidad and Tobago',
    tn: 'Tunisia',
    tr: 'Turkey',
    tm: 'Turkmenistan',
    tv: 'Tuvalu',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'United Arab Emirates',
    gb: 'United Kingdom',
    us: 'United States',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    vu: 'Vanuatu',
    va: 'Holy See (Vatican City)',
    ve: 'Venezuela',
    vn: 'Vietnam',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabwe',
};

const CustomizedPhoneInput = (props) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: props.maxWidth,
                minWidth: props.minWidth,
                width: props.width ? props.width : '100%',
                justifyContent: 'start',
                margin: props.margin,
                marginTop: props.marginTop,
                marginBottom: props.marginBottom,
                marginRight: props.marginRight,
                marginLeft: props.marginLeft,
            }}
        >
            {props.label && (
                <Typography
                    variant='desktop_label'
                    component='div'
                    sx={{
                        marginBottom: '4px',
                        color: props.labelColor ? props.labelColor : theme.palette.grayscale.grey1,
                    }}
                >
                    {props.label}
                </Typography>
            )}
            <PhoneInput
                country={'hk'}
                placeholder={'Phone No.'}
                value={props.form.phone_number}
                onChange={(value, country, e, formattedValue) => {
                    let countryCode = country.countryCode;
                    let countryName = ShortCodeToCountry[countryCode]; // TODO: This country name match with the names in `countryData.js`
                    props.setForm({ ...props.form, phone_number: value, country: countryName });
                }}
            />
        </Box>
    );
};

export default CustomizedPhoneInput;
