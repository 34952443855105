import _ from 'lodash';
import * as AmuroNetworkController from '../controller/amuro_network_controller';

export const firebaseLogin = async (firebaseJwt, options = {}) => {
    let body = {};
    if (options.company_name) {
        body.company_name = options.company_name;
    }
    if (options.phone_number) {
        body.phone_number = options.phone_number;
    }
    return AmuroNetworkController.post(`/web/user/firebase-user-login`, body, {
        headers: AmuroNetworkController.insertCredentialsIntoHeaders({}, firebaseJwt, null),
    });
};

export const corporateRequestAccess = async (company_name, phone_number, email) => {
    return AmuroNetworkController.post(`/web/user/corporate-request-access`, {
        company_name: company_name,
        phone_number: phone_number,
        email: email,
    });
};

export const verifyCorporatePasscode = async (passcode) => {
    return AmuroNetworkController.post(`/web/user/verify-passcode`, {
        passcode: passcode,
    });
};
