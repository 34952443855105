// React / other libraries
import * as React from 'react';
import _ from 'lodash';

// MUI / Components
import { Box, TextField, Typography } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

const CustomizedTextField = (props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: props.maxWidth,
                minWidth: props.minWidth,
                width: props.width,
                justifyContent: 'start',
                margin: props.margin,
                marginTop: props.marginTop,
                marginBottom: props.marginBottom,
                marginRight: props.marginRight,
                marginLeft: props.marginLeft,
                svg: {
                    color: props.iconColor ? props.iconColor : theme.palette.grayscale.white,
                },
            }}
        >
            {props.label && (
                <Typography
                    variant='desktop_label'
                    component='div'
                    sx={{
                        marginBottom: '4px',
                        color: props.labelColor ? props.labelColor : theme.palette.grayscale.grey1,
                        opacity: props.noDisabledUI ? '1' : props.disabled ? '0.3' : '1',
                    }}
                >
                    {props.label}
                </Typography>
            )}
            <TextField
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                InputProps={props.InputProps}
                sx={{
                    opacity: props.noDisabledUI ? '1' : props.disabled ? '0.3' : '1',
                    '& .MuiInputBase-root': {
                        borderRadius: '12px',
                        height: props.height ? props.height : '46px',
                        backgroundColor: props.backgroundColor,
                        padding: props.padding ? props.padding : '0px 16px',
                    },
                    '& ::placeholder': {
                        color: props.placeholderTextColor ? props.placeholderTextColor : theme.palette.grayscale.grey2,
                        fontWeight: '400',
                        fontSize: props.placeholderFontSize ? props.placeholderFontSize : '16px',
                        lineHeight: props.placeholderLineHeight ? props.placeholderLineHeight : '22.4px',
                        letterSpacing: props.placeholderLetterSpacing ? props.placeholderLetterSpacing : '0.15px',
                    },
                    '& .MuiOutlinedInput-root.Mui-disabled': {
                        'input:-webkit-autofill': {
                            border: 'none',
                            WebkitBoxShadow: `0 0 0 1000px ${
                                props.backgroundColor ? props.backgroundColor : theme.palette.background.dark
                            } inset`,
                            WebkitTextFillColor: props.textColor ? props.textColor : theme.palette.grayscale.white,
                            fontSize: props.fontSize ? props.fontSize : '16px',
                            padding: props.padding ? props.padding : '0px 16px',
                        },
                        'input:disabled': {
                            WebkitTextFillColor: props.textColor ? props.textColor : theme.palette.grayscale.white,
                        },
                        '& fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                        '&:hover fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                        '&:disabled fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        'input:-webkit-autofill': {
                            border: 'none',
                            WebkitBoxShadow: `0 0 0 1000px ${
                                props.backgroundColor ? props.backgroundColor : theme.palette.background.dark
                            } inset`,
                            WebkitTextFillColor: props.textColor ? props.textColor : theme.palette.grayscale.white,
                            fontSize: props.fontSize ? props.fontSize : '16px',
                            padding: props.padding ? props.padding : '0px 16px',
                        },
                        '& fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                        '&:hover fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                        '&:disabled fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: props.noBorder ? null : props.borderColor ? props.borderColor : theme.palette.grayscale.grey2,
                        },
                    },
                    '& .MuiInputBase-root': {
                        fontWeight: '400',
                        fontSize: props.fontSize ? props.fontSize : '16px',
                        lineHeight: props.lineHeight ? props.lineHeight : '22.4px',
                        letterSpacing: props.letterSpacing ? props.letterSpacing : '0.15px',
                        borderRadius: props.borderRadius ? props.borderRadius : '12px',
                        height: props.height ? props.height : '46px',
                        color: props.textColor ? props.textColor : theme.palette.grayscale.white,
                        backgroundColor: props.backgroundColor ? props.backgroundColor : theme.palette.background.dark,
                        padding: props.padding,
                    },
                }}
                disabled={props.disabled}
                {...props.others}
            />
            {props.input}
        </Box>
    );
};

export default React.memo(CustomizedTextField, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
