// React / other libraries
import * as React from 'react';

// Context
import { UserContext } from '../context/UserProvider';
import { SnackbarDispatchContext } from '../context/Snackbar';

const useSignIn = () => {
    const { enqueueSnackbar } = React.useContext(SnackbarDispatchContext);
    const [signInForm, setSignInForm] = React.useState({
        email: '',
        password: '',
    });
    const [loading, setLoading] = React.useState(false);

    const { signInWithEmail } = React.useContext(UserContext);

    const validateForm = () => {
        if (signInForm.email === '') {
            enqueueSnackbar('warning', 'Email cannot be emptied.');
            return false;
        }

        if (signInForm.password === '') {
            enqueueSnackbar('warning', 'Password cannot be emptied.');
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            signInWithEmail(signInForm.email, signInForm.password, () => {
                setLoading(false);
            });
        }
    };

    return {
        loading,
        signInForm,
        setSignInForm,
        validateForm,
        handleSubmit,
    };
};

export default useSignIn;
