// Import FirebaseAuth and firebase.
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import { onAuthStateChanged } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/cv-analysis',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            defaultCountry: 'HK',
            recaptchaParameters: {
                type: 'image', // 'audio'
                size: 'invisible', // 'normal', 'invisible' or 'compact'
                badge: 'bottomright', //' bottomright' or 'inline' applies to invisible.
            },
        },
    ],
    callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
            console.log(`Firebase sign in succeeded`);
            // console.log(`authResult: ${authResult}`);
            // console.log(`redirectUrl: ${redirectUrl}`);
        },
        signInFailure: (error) => {
            console.log(error);
        },
    },
};

const StyledFirebaseAuth = ({ uiConfig, firebaseAuth }) => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        // Get or Create a firebaseUI instance.
        const firebaseUiWidget = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebaseAuth);
        if (uiConfig.signInFlow === 'popup') firebaseUiWidget.reset();

        // Track the auth state to reset firebaseUi if the user signs out.
        const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
            if (!user && userSignedIn) firebaseUiWidget.reset();
            setUserSignedIn(!!user);
        });

        // Render the firebaseUi Widget.
        // @ts-ignore
        firebaseUiWidget.start(elementRef.current, uiConfig);

        return () => {
            unregisterAuthObserver();
            firebaseUiWidget.reset();
        };
    }, [firebaseui, uiConfig]);

    return <div ref={elementRef} />;
};

function FirebaseSignIn(props) {
    if (props.overrideSignInOptions) {
        uiConfig.signInOptions = props.overrideSignInOptions;
    }
    return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />;
}

export default FirebaseSignIn;
