import * as React from 'react';

// Create two context:
// SidebarContext: to query the context state
// SidebarDispatchContext: to mutate the context state
const SidebarContext = React.createContext(undefined);
const SidebarDispatchContext = React.createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function SidebarProvider({ children }) {
    const [sidebarOpen, setSidebarOpen] = React.useState(false);

    const openSidebar = () => {
        setSidebarOpen(true);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <SidebarContext.Provider value={{ sidebarOpen }}>
            <SidebarDispatchContext.Provider value={{ openSidebar, closeSidebar }}>{children}</SidebarDispatchContext.Provider>
        </SidebarContext.Provider>
    );
}

export { SidebarProvider, SidebarContext, SidebarDispatchContext };
