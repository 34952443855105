import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    sideBarWrapper: {
        display: 'flex',
        position: 'fixed',
        top: '0',
        right: '0',
        height: '100vh',
        width: '100vw',
        zIndex: '12',
    },
    sideBarWrapperNotActive: {
        transform: 'translateX(100%)',
        transition: 'transform .4s ease',
        willChange: 'transform',
    },
    sideBarWrapperActive: {
        transform: 'translateX(0)',
        transition: 'transform .4s ease',
        willChange: 'transform',
    },
    filter: {
        // background: "rgba(0, 0, 0, 0.75)",
        height: '100vh',
        width: 'calc(100% - 450px)',
        zIndex: '10',
        [theme.breakpoints.down('md')]: {
            // right: "375px !important",
            width: 'calc(100% - 375px)',
        },
    },
    sideBarContainer: {
        backgroundColor: theme.palette.theme.accent.transparentDark,
        backdropFilter: 'blur(30px)',
        position: 'relative',
        height: '100vh',
        width: '450px',
        // zIndex: "12",
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.down('md')]: {
            width: '375px',
        },
    },
    accountInfoContainer: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        width: '100%',
        height: '255px',
        borderBottom: `1px solid ${theme.palette.grayscale.grey4}`,
        padding: '100px 40px 40px 40px',
        gap: '24px',
        [theme.breakpoints.down('md')]: {
            height: '246px',
            padding: '100px 40px 40px 40px',
        },
    },
    proPic: {
        flex: '0 0 auto',
        height: '64px',
        width: '64px',
        background: '#BED0EE',
        overflow: 'hidden',
        borderRadius: '50%',
    },
    accountInfo: {
        width: '282px',
        [theme.breakpoints.down('md')]: {
            width: '207px',
        },
    },
    userConnectText: {
        color: theme.palette.grayscale.white,
    },
    custodianWalletInfo: {
        padding: '16px 0 8px 0',
    },
    custodianWalletText: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: theme.palette.grayscale.white,
    },
    walletText: {
        color: theme.palette.grayscale.grey1,
    },

    options: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        padding: '40px 40px',
        overflowY: 'visible',
        listStyle: 'none',
    },
    option: {
        color: theme.palette.grayscale.grey1,
        paddingBottom: '16px',
        textDecoration: 'none',
        cursor: 'pointer',

        // hover
        '&:hover': {
            color: theme.palette.grayscale.white,
        },
    },
    closeButton: {
        position: 'absolute !important',
        top: '35px',
        right: '37px',
        height: '12px',
    },
}));

export default useStyles;
