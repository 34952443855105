// React / Other libraries
import * as React from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

// MUI / Components
import { useTheme } from '@mui/styles';
import useStyles from './styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import NavBar from '../../components/NavBar/LandingNavBar';

// Images
import { ReactComponent as AmuroLogo } from '../../assets/images/amuro_logo.svg';
import { ReactComponent as SinoLogo } from '../../assets/images/sino_logo.svg';
import { ReactComponent as HKSTPLogo } from '../../assets/images/hkstp_logo.svg';
import { ReactComponent as SwireLogo } from '../../assets/images/swire_logo.svg';
import { ReactComponent as CathayLogo } from '../../assets/images/cathay_logo.svg';
import { ReactComponent as Frame1796 } from '../../assets/images/frame_1796.svg';
import { ReactComponent as Orbit } from '../../assets/images/orbit.svg';
import CorporateScreenshotOne from '../../assets/images/corporate_screenshot_1.png';
import CorporateScreenshotTwo from '../../assets/images/corporate_screenshot_2.png';
import JobseekerScreenshotOne from '../../assets/images/jobseeker_screenshot_1.png';
import JobseekerScreenshotTwo from '../../assets/images/jobseeker_screenshot_2.png';
import FilledButton from '../../components/Buttons/FilledButton';

// Context
import { UserContext } from '../../context/UserProvider';

const Landing = ({}) => {
    const theme = useTheme();
    const styles = useStyles();
    const { userDetails } = React.useContext(UserContext);
    const [isJobseeker, setIsJobseeker] = React.useState(false);
    const isBelow700px = useMediaQuery(theme.breakpoints.down('700'));
    const navigate = useNavigate();

    return (
        <Box className={`${styles['.full-width-container']}`}>
            <Box className={`${styles['.content-container']}`}>
                <Box className={`${styles['.ellipse-236']}`}></Box>
                <Box className={`${styles['.ellipse-2362']}`}></Box>
                <Orbit className={`${styles['.group-630']}`} />
                <NavBar isJobseeker={isJobseeker} setIsJobseeker={setIsJobseeker} />
                <Box className={`${styles['.frame-1804']}`}>
                    <AmuroLogo className={`${styles['.amuro-logo']}`} />
                    <Box className={`${styles['.frame-1740']}`}>{isJobseeker ? <Jobseekers /> : <Corporates />}</Box>
                    <Box className={`${styles['.frame-1966']}`}>
                        <img src={isJobseeker ? JobseekerScreenshotTwo : CorporateScreenshotOne} className={`${styles['.screenshot-1']}`} />
                        <img src={isJobseeker ? JobseekerScreenshotOne : CorporateScreenshotTwo} className={`${styles['.screenshot-2']}`} />
                    </Box>
                </Box>
                <Box className={`${styles['.frame-1794']}`}>
                    <Box className={`${styles['.frame-1797']}`}>
                        <Typography variant={'desktop_label'} className={`${styles['.companies-we-ve-worked-with']}`}>
                            {`Companies we’ve worked with`}
                        </Typography>
                        <Box className={`${styles['.frame-1792']}`}>
                            <SinoLogo className={`${styles['.sino-logo']}`} />
                            <HKSTPLogo className={`${styles['.hkstp-logo']}`} />
                            <SwireLogo className={`${styles['.swire-logo']}`} />
                            <CathayLogo className={styles['cathay-logo']} />
                        </Box>
                    </Box>
                    <Frame1796 className={`${styles['.frame-1796']}`} />
                    <Box className={`${styles['.frame-1795']}`}>
                        <Typography variant={'desktop_label'} className={`${styles['.email']}`}>
                            {'Email'}
                        </Typography>
                        <Typography variant={'desktop_label'} className={`${styles['.support-amuro-io']}`}>
                            {'support@amuro.io'}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const Corporates = () => {
    const styles = useStyles();
    const theme = useTheme();
    const isBelow800px = useMediaQuery(theme.breakpoints.down('800'));

    return (
        <>
            <Typography component='div' className={`${styles['.title']}`}>
                {`Strategic AI-Powered CV Screening`}
            </Typography>
            <Typography component='div' className={`${styles['.subtitle']}`}>
                {isBelow800px
                    ? `Amuro empowers hiring teams to overcome the challenge of time-consuming and potentially biased CV screening.\n\nWe deliver quick, unbiased, and in-depth assessments with our AI-powered CV screening assistant.`
                    : `Amuro empowers hiring teams to overcome the challenge of time-consuming and potentially biased CV screening.\nWe deliver quick, unbiased, and in-depth assessments with our AI-powered CV screening assistant.`}
            </Typography>
        </>
    );
};

const Jobseekers = () => {
    const styles = useStyles();

    return (
        <>
            <Typography component='div' className={`${styles['.title']}`}>{`Mapping Your CV to Your Dream Job`}</Typography>
            <Typography component='div' className={`${styles['.subtitle']}`}>
                {`Amuro assists job seekers in eliminating guesswork, by providing real-time, tailored CV compatibility assessments with our innovative AI-driven tool.`}
            </Typography>
        </>
    );
};

export default React.memo(Landing, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});
