// React / other libraries
import * as React from 'react';
import { Link } from 'react-router-dom';

// MUI / Components
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Navigation from '../Navigation/Navigation';
import useStyles from './styles';
import CustomizedIconButton from '../Buttons/IconButton';

// Images
import { ReactComponent as AmuroLogo } from '../../assets/images/amuro_logo.svg';
import { ReactComponent as MenuIcon } from '../../assets/images/menu_icon.svg';

// Context
import { UserContext } from '../../context/UserProvider';
import { SidebarDispatchContext } from '../../context/SideBar';

const NavBar = ({ routerPrefix }) => {
    const theme = useTheme();
    const styles = useStyles();
    const { userDetails } = React.useContext(UserContext);
    const { openSidebar } = React.useContext(SidebarDispatchContext);
    const isBelow700px = useMediaQuery(theme.breakpoints.down('700'));

    return (
        <Box className={`${styles['.nav-bar']}`}>
            <Link to='/'>
                <AmuroLogo className={`${styles['.nav-bar-logo']}`} />
            </Link>
            {!isBelow700px && userDetails.firebaseUser && <Navigation routePrefix={routerPrefix} />}
            {userDetails.firebaseUser && (
                <Box className={`${styles['.navigation-menu-container']}`}>
                    <CustomizedIconButton
                        onClick={() => {
                            openSidebar();
                        }}
                    >
                        <MenuIcon className={styles['.navigation-menu']} />
                    </CustomizedIconButton>
                </Box>
            )}
        </Box>
    );
};

export default NavBar;
