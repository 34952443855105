import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const newTheme = createTheme({
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: {
                    color: '#304D86',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    // background: 'rgba(120, 121, 136, 0.1)',
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 376,
            md: 900,
            lg: 1440,
            xl: 1800,
        },
    },
    palette: {
        theme: {
            graphite: '#464854',
            moonRock: '#E9E9E9',
            NGC6905: '#304D86',
            NGC6905_75: 'rgba(48, 77, 134, 0.75)',
            abeil39: '#7AC4C9',
            nebula: 'linear-gradient(93.89deg, #304D86 -6.56%, #7AC4C9 115.4%)',
            accent: {
                light: '#647AA4',
                transparentDark: 'rgba(0, 0, 0, 0.5)',
                transparentLight: 'rgba(120, 121, 136, 0.1)',
                theme75: 'rgba(48, 77, 134, 0.75)',
                theme50: 'rgba(48, 77, 134, 0.5)',
                theme25: 'rgba(48, 77, 134, 0.25)',
                theme10: 'rgba(48, 77, 134, 0.1)',
            },
        },
        grayscale: {
            white: '#FCFCFC',
            grey1: '#B6B6BF',
            grey2: '#787988',
            grey3: '#585A67',
            grey4: '#2C2D35',
            black: '#08080E',
        },
        background: {
            dark: '#15161E',
            light: '#EBEBEB',
        },
    },
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontFamily: ['Oxygen', 'Notosans HK'].join(','),
        fontSize: 12,
        fontType: {
            english: 'Oxygen',
            number: 'Oxygen',
            chinese: 'Notosans HK',
        },
        fontWeight: {
            regular: '400',
            semiBold: '600',
            Bold: '700',
        },
        // Two sets of typography variants
        desktop_hero: {
            fontSize: '40px',
            fontWeight: '600',
            lineHeight: '120%',
        },
        desktop_sidekick: {
            fontSize: '26px',
            fontWeight: '400',
            lineHeight: '120%',
        },
        desktop_title: {
            fontSize: '22px',
            fontWeight: '700',
            lineHeight: '120%',
        },
        desktop_subTitle: {
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '125%',
            letterSpacing: '0.15px',
        },
        desktop_header: {
            fontSize: '16px',
            fontWeight: '700',
            lineHeight: '120%',
        },
        desktop_body: {
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '120%',
        },
        desktop_bodyBold: {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '120%',
        },
        desktop_label: {
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '120%',
        },
        desktop_buttonPrimary: {
            fontSize: '14px',
            fontWeight: '700',
            lineHeight: '120%',
        },
        desktop_buttonSecondary: {
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '120%',
        },
        desktop_footnote: {
            fontSize: '10px',
            fontWeight: '400',
            lineHeight: '120%',
        },
    },
});

export default newTheme;
