// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomizedTextField from '../../components/Form/TextField';
import FilledButton from '../../components/Buttons/FilledButton';
import NavBar from '../../components/NavBar/NavBar';
import useStyles from './styles';

// Hooks
import useSignIn from '../../hooks/useSignIn';
import FirebaseSignIn from '../../components/FirebaseSignIn/FirebaseSignIn';
import { UserContext } from '../../context/UserProvider';

const SignIn = () => {
    const theme = useTheme();
    const styles = useStyles();
    const navigate = useNavigate();
    const { userDetails } = React.useContext(UserContext);
    const { loading, signInForm, setSignInForm, validateForm, handleSubmit } = useSignIn();

    // Handle redirection
    React.useEffect(() => {
        console.log('firebaseUser', userDetails.firebaseUser);
        if (userDetails.firebaseUser && window.location.pathname === '/corp') {
            navigate('/corp/cv-analysis');
        }
    }, [userDetails.firebaseUser]);

    return (
        <Box className={`${styles['.sign-in-container']}`}>
            <NavBar />
            <Box className={`${styles['.sign-in-form']}`} component='form' onSubmit={handleSubmit}>
                <Typography variant='desktop_body' className={`${styles['.sign-in-title']}`}>
                    {'Sign up / Sign in to continue'}
                </Typography>
                <Box className={`${styles['.sign-in-text-field-group']}`}>
                    <CustomizedTextField
                        value={signInForm.email}
                        onChange={(e) => setSignInForm({ ...signInForm, email: e.target.value })}
                        label='Email'
                        backgroundColor={theme.palette.grayscale.grey4}
                        borderColor='transparent'
                        height='40px'
                        minWidth='327px'
                        width='80%'
                        maxWidth='400px'
                        borderRadius='4px'
                    />
                    <CustomizedTextField
                        value={signInForm.password}
                        onChange={(e) => setSignInForm({ ...signInForm, password: e.target.value })}
                        label='Password'
                        backgroundColor={theme.palette.grayscale.grey4}
                        borderColor='transparent'
                        height='40px'
                        minWidth='327px'
                        width='80%'
                        maxWidth='400px'
                        borderRadius='4px'
                        others={{
                            type: 'password',
                        }}
                    />
                </Box>
                <Box className={`${styles['.sign-in-button-group']}`}>
                    <FilledButton
                        borderRadius='8px'
                        padding='12px 32px'
                        color={theme.palette.theme.nebula}
                        hoverColor={theme.palette.theme.nebula}
                        textColor={theme.palette.grayscale.white}
                        disabled={loading}
                        others={{
                            type: 'submit',
                        }}
                    >
                        {loading ? (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: theme.palette.grayscale.white,
                                }}
                            />
                        ) : (
                            <Typography variant='desktop_buttonPrimary'>{'Sign In'}</Typography>
                        )}
                    </FilledButton>
                    <Typography
                        variant='desktop_buttonSecondary'
                        className={`${styles['.sign-up-instead']}`}
                        onClick={() => {
                            navigate('/corp/sign-up');
                        }}
                    >
                        {'Or Sign Up'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SignIn;
