import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.pricing-container': {
        borderRadius: '4px',
        borderStyle: 'solid',
        borderColor: theme.palette.theme.NGC6905,
        borderWidth: '1px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        width: '184px',
        height: '242px',
        position: 'relative',
    },
    '.frame-1946': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        position: 'relative',
    },
    '.quota': {
        color: theme.palette.theme.abeil39,
        textAlign: 'left',
        position: 'relative',
    },
    '.pricing': {
        color: theme.palette.grayscale.white,
        textAlign: 'left',
        position: 'relative',
    },
    '.frame-1935': {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        flex: '1',
        position: 'relative',
    },
    '.each-quota-includes': {
        color: theme.palette.grayscale.grey1,
        textAlign: 'left',
        position: 'relative',
    },
    '.frame-1948': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        position: 'relative',
    },
    '.job-description-count': {
        color: theme.palette.grayscale.white,
        textAlign: 'left',
        position: 'relative',
    },
    '.cv-count': {
        color: theme.palette.grayscale.white,
        textAlign: 'left',
        position: 'relative',
    },
    '.buttons-secondary': {
        background: theme.palette.theme.accent.transparentLight,
        '&:hover': {
            background: theme.palette.theme.accent.transparentLight,
        },
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        gap: '0px',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        flexShrink: '0',
        height: '32px',
        position: 'relative',
        overflow: 'hidden',
    },
    '.secondary': {
        color: theme.palette.theme.moonRock,
        textAlign: 'left',
        position: 'relative',
    },
    '.buttons-primary': {
        background: theme.palette.theme.NGC6905,
        '&:hover': {
            background: theme.palette.theme.NGC6905,
        },
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        gap: '0px',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        flexShrink: '0',
        height: '32px',
        position: 'relative',
        overflow: 'hidden',
    },
    '.primary': {
        color: theme.palette.grayscale.white,
        textAlign: 'center',
        position: 'relative',
    },
    '.frame-1947': {
        background: theme.palette.theme.NGC6905,
        borderRadius: '4px 4px 0px 0px',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexShrink: '0',
        position: 'absolute',
        right: '0px',
        left: '0px',
        top: '-29px',
    },
    '.most-popular': {
        color: theme.palette.grayscale.white,
        textAlign: 'center',
        position: 'relative',
        flex: '1',
    },
}));

export default useStyles;
