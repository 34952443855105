// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomizedTextField from '../../components/Form/TextField';
import FilledButton from '../../components/Buttons/FilledButton';
import NavBar from '../../components/NavBar/NavBar';
import useStyles from './styles';

// Hooks
import FirebaseSignIn from '../../components/FirebaseSignIn/FirebaseSignIn';
import { UserContext } from '../../context/UserProvider';

const SignIn = () => {
    const theme = useTheme();
    const styles = useStyles();
    const navigate = useNavigate();
    const { userDetails } = React.useContext(UserContext);

    // Handle redirection
    React.useEffect(() => {
        console.log('firebaseUser', userDetails.firebaseUser);
        if (userDetails.firebaseUser && window.location.pathname === '/user') {
            navigate('/user/cv-analysis');
        }
    }, [userDetails.firebaseUser]);

    return (
        <Box className={`${styles['.sign-in-container']}`}>
            <NavBar />
            {userDetails.firebaseUser ? (
                <Box
                    sx={{
                        minHeight: '800px',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: theme.palette.background.dark,
                    }}
                >
                    <CircularProgress size={100} color='primary' />
                </Box>
            ) : (
                <Box className={`${styles['.sign-in-form']}`} component='form'>
                    <Typography variant='desktop_body' className={`${styles['.sign-in-title']}`}>
                        {'Sign in to continue'}
                    </Typography>
                    <Box className={`${styles['.sign-in-text-field-group']}`}>
                        <FirebaseSignIn />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SignIn;
