import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.buttons-primary': {
        background: `${theme.palette.theme.NGC6905} !important`,
        '&:hover': {
            background: `${theme.palette.theme.NGC6905} !important`,
        },
        '&:disabled': {
            background: `${theme.palette.grayscale.grey3} !important`,
        },
        borderRadius: '4px !important',
        padding: '8px 16px 8px 16px !important',
        display: 'flex !important',
        flexDirection: 'row!important',
        gap: '0px !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        alignSelf: 'stretch !important',
        flexShrink: '0 !important',
        height: '32px !important',
        position: 'relative !important',
        overflow: 'hidden !important',
        '& > div span': {
            color: `${theme.palette.grayscale.white} !important`,
            textAlign: 'center !important',
            position: 'relative !important',
        },
    },
    '.buttons-secondary': {
        background: `${theme.palette.theme.accent.transparentLight} !important`,
        '&:hover': {
            background: `${theme.palette.theme.accent.transparentLight} !important`,
        },
        '&:disabled': {
            background: `${theme.palette.grayscale.grey3} !important`,
        },
        borderRadius: '4px !important',
        padding: '8px 16px 8px 16px !important',
        display: 'flex !important',
        flexDirection: 'row!important',
        gap: '0px !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        alignSelf: 'stretch !important',
        flexShrink: '0 !important',
        height: '32px !important',
        position: 'relative !important',
        overflow: 'hidden !important',
        '& > div span': {
            color: `${theme.palette.grayscale.white} !important`,
            textAlign: 'center !important',
            position: 'relative !important',
        },
    },
}));

export default useStyles;
