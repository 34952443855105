import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.top-up-container': {
        position: 'relative',
        minHeight: '800px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden',
        background: theme.palette.background.dark,
    },
    '.ellipse-236': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '545px',
        height: '545px',
        position: 'absolute',
        left: '106px',
        top: '555px',
        filter: 'blur(250px)',
    },
    '.ellipse-237': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '225px',
        height: '225px',
        position: 'absolute',
        left: '153px',
        top: '-215px',
        filter: 'blur(150px)',
    },
    '.ellipse-238': {
        background: theme.palette.theme.nebula,
        borderRadius: '50%',
        width: '225px',
        height: '225px',
        position: 'absolute',
        right: '-20px',
        bottom: '469px',
        filter: 'blur(190px)',
    },
    '.pricing-option-group': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '195px',
        gap: '40px',
        flexWrap: 'wrap',
    },
}));

export default useStyles;
