import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.nav-bar': {
        display: 'flex',
        padding: '24px 32px 24px 24px',
        alignItems: 'center',
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        zIndex: '10',

        [theme.breakpoints.down('md')]: {
            padding: '16px 24px 16px 16px',
        },
    },
    '.nav-bar-logo': {
        width: '122px',
        height: '36px',
        [theme.breakpoints.down('741px')]: {
            width: '102px;',
            height: '30px;',
        },
    },
    '.navigation-menu-container': {
        width: '122px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    '.navigation-menu': {
        position: 'relative',
        overflow: 'visible',
    },
    '.frame-209': {
        display: 'flex',
        flexDirection: 'row',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexShrink: '0',
        position: 'relative',
    },
    '.switch': {
        color: 'var(--grayscale-white, #fcfcfc)',
        textAlign: 'center',
        font: 'var(--desktop-button-primary, 700 14px/120% "Oxygen", sans-serif)',
        position: 'relative',
        cursor: 'pointer',
    },
    '.sign-in': {
        color: 'var(--grayscale-white, #fcfcfc)',
        textAlign: 'center',
        font: 'var(--desktop-button-primary, 700 14px/120% "Oxygen", sans-serif)',
        position: 'relative',
        cursor: 'pointer',
    },
    '.buttons-primary': {
        background: 'var(--theme-ngc-6905, #304d86)',
        borderRadius: '4px',
        padding: '8px 16px 8px 16px',
        display: 'flex',
        flexDirection: 'row',
        gap: '0px',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: '0',
        height: '32px',
        position: 'relative',
        overflow: 'hidden',
    },
    '.primary': {
        color: 'var(--grayscale-white, #fcfcfc)',
        textAlign: 'center',
        font: 'var(--desktop-button-primary, 700 14px/120% "Oxygen", sans-serif)',
        position: 'relative',
    },
}));

export default useStyles;
