// React or other libraries
import * as React from 'react';

// MUI / Components
import { useTheme } from '@mui/styles';
import useStyles from './styles';
import { Box } from '@mui/material';
import NavBar from '../../components/NavBar/NavBar';
import PricingContainer from '../../components/PricingContainer';

const TopUp = () => {
    const theme = useTheme();
    const styles = useStyles();
    const options = [
        {
            quota: '1 Quota',
            pricing: 'HKD 500',
            jobDescriptionCount: '1 Job Description',
            cvCount: '200 CVs',
        },
        {
            quota: '5 Quotas',
            pricing: 'HKD 2000',
            jobDescriptionCount: '1 Job Description',
            cvCount: '200 CVs',
            isMostPopular: true,
        },
        {
            quota: '15 Quotas',
            pricing: 'HKD 5500',
            jobDescriptionCount: '1 Job Description',
            cvCount: '200 CVs',
        },
        {
            quota: '50+ Quotas',
            pricing: "Let's Talk",
            jobDescriptionCount: '1 Job Description',
            cvCount: '200 CVs',
            buttonLabel: 'Contact Amuro',
        },
    ];

    return (
        <Box className={`${styles['.top-up-container']}`}>
            <NavBar />
            <Box className={`${styles['.ellipse-236']}`}></Box>
            <Box className={`${styles['.ellipse-237']}`}></Box>
            <Box className={`${styles['.ellipse-238']}`}></Box>
            <Box className={`${styles['.pricing-option-group']}`}>
                {options.map((option, index) => {
                    return (
                        <PricingContainer
                            key={index}
                            quota={option.quota}
                            pricing={option.pricing}
                            jobDescriptionCount={option.jobDescriptionCount}
                            cvCount={option.cvCount}
                            isMostPopular={option.isMostPopular}
                            buttonLabel={option.buttonLabel}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

export default TopUp;
