// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI / Components
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomizedTextField from '../../components/Form/TextField';
import FilledButton from '../../components/Buttons/FilledButton';
import NavBar from '../../components/NavBar/NavBar';
import useStyles from './styles';
import CustomizedPhoneInput from '../../components/Form/PhoneInput/DarkPhoneInput';

// Hooks
import useSignUp from '../../hooks/useSignUp';

const SignUp = () => {
    const theme = useTheme();
    const styles = useStyles();
    const navigate = useNavigate();
    const { loading, signUpForm, setSignUpForm, validateForm, handleSubmit } = useSignUp();

    return (
        <Box className={`${styles['.sign-up-container']}`}>
            <NavBar />
            <Box className={`${styles['.sign-up-form']}`} component='form' onSubmit={handleSubmit}>
                <Typography variant='desktop_body' className={`${styles['.sign-up-title']}`}>
                    {'Sign up / Sign in to continue'}
                </Typography>
                <Box className={`${styles['.sign-up-text-field-group']}`}>
                    <CustomizedTextField
                        value={signUpForm.company_name}
                        onChange={(e) => setSignUpForm({ ...signUpForm, company_name: e.target.value })}
                        label='Company Name'
                        backgroundColor={theme.palette.grayscale.grey4}
                        borderColor='transparent'
                        height='40px'
                        width='400px'
                        borderRadius='4px'
                    />
                    <CustomizedPhoneInput height='40px' width='400px' label='Phone No.' form={signUpForm} setForm={setSignUpForm} />
                    <CustomizedTextField
                        value={signUpForm.email}
                        onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
                        label='Email'
                        backgroundColor={theme.palette.grayscale.grey4}
                        borderColor='transparent'
                        height='40px'
                        width='400px'
                        borderRadius='4px'
                    />
                    <CustomizedTextField
                        value={signUpForm.password}
                        onChange={(e) => setSignUpForm({ ...signUpForm, password: e.target.value })}
                        label='Password'
                        backgroundColor={theme.palette.grayscale.grey4}
                        borderColor='transparent'
                        height='40px'
                        width='400px'
                        borderRadius='4px'
                        others={{
                            type: 'password',
                        }}
                    />
                </Box>
                <Box className={`${styles['.sign-up-button-group']}`}>
                    <FilledButton
                        borderRadius='8px'
                        padding='12px 32px'
                        color={theme.palette.theme.nebula}
                        hoverColor={theme.palette.theme.nebula}
                        textColor={theme.palette.grayscale.white}
                        disabled={loading}
                        others={{
                            type: 'submit',
                        }}
                    >
                        {loading ? (
                            <CircularProgress
                                size={20}
                                sx={{
                                    color: theme.palette.grayscale.white,
                                }}
                            />
                        ) : (
                            <Typography variant='desktop_buttonPrimary'>{'Sign Up'}</Typography>
                        )}
                    </FilledButton>
                    <Typography
                        variant='desktop_buttonSecondary'
                        className={`${styles['.sign-in-instead']}`}
                        onClick={() => {
                            navigate('/corp');
                        }}
                    >
                        {'Or Sign In'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default SignUp;
