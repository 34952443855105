// React / Other libraries
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// MUI / Components
import { useTheme } from '@mui/styles';
import useStyles from './styles';
import { Box, Rating, Skeleton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import NavBar from '../../components/NavBar/NavBar';
import FilledButton from '../../components/Buttons/FilledButton';
import { Error } from '@mui/icons-material';

// Images
import { ReactComponent as ProcessingIcon } from '../../assets/images/processing_icon.svg';
import { ReactComponent as BackIcon } from '../../assets/images/back_icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/check_icon.svg';
import { ReactComponent as DashIcon } from '../../assets/images/dash_icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close_icon.svg';
import { ReactComponent as EmptyStar } from '../../assets/images/empty_star.svg';
import { ReactComponent as Star } from '../../assets/images/star.svg';

// Hooks / Utils
import useResultDetail from '../../hooks/useResultDetail';
import { convertJSONKey, isNameValid } from '../../utils/utils';

const ResultDetail = () => {
    const styles = useStyles();
    const { requestId } = useParams();

    const {
        request,
        apiRef,
        rows,
        selectionModel,
        setSelectionModel,
        getColumns,
        columnVisibilityModel,
        highlightedRow,
        setHighlightedRow,
        handleRowSelect,
        handleRowClick,
        exportToXLSX,
        starCvHandler,
    } = useResultDetail(requestId);

    const getComponent = React.useCallback(() => {
        if (request == null) {
            return <ResultLoading />;
        }

        if (request?.status === 'pending' || request?.status === 'processing') {
            return <ResultProcessing />;
        }

        if (request?.status === 'error') {
            return <ResultError />;
        }

        if (request?.status === 'completed') {
            return (
                <ResultCompleted
                    apiRef={apiRef}
                    rows={rows}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                    getColumns={getColumns}
                    columnVisibilityModel={columnVisibilityModel}
                    highlightedRow={highlightedRow}
                    setHighlightedRow={setHighlightedRow}
                    handleRowSelect={handleRowSelect}
                    handleRowClick={handleRowClick}
                    exportToSpreadsheet={exportToXLSX}
                    starCvHandler={starCvHandler}
                />
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, highlightedRow, request]);

    return (
        <Box className={`${styles['.results-container']}`}>
            <NavBar />
            <Box className={`${styles['.ellipse-236']}`}></Box>
            <Box className={`${styles['.ellipse-237']}`}></Box>
            {getComponent()}
        </Box>
    );
};

export default ResultDetail;

const ResultLoading = ({}) => {
    const theme = useTheme();
    const styles = useStyles();
    const navigate = useNavigate();

    return (
        <Box className={`${styles['.frame-1908']}`}>
            <Box className={`${styles['.frame-1909']}`}></Box>
            <Box className={`${styles['.frame-1911']}`}>
                <Skeleton animation='pulse' variant='rounded' className={`${styles['.result-container-skeleton']}`} />
            </Box>
        </Box>
    );
};

const ResultProcessing = () => {
    const styles = useStyles();
    const navigate = useNavigate();

    return (
        <Box className={`${styles['.frame-1869']}`}>
            <ProcessingIcon className={`${styles['.fluent-mdl-2-processing']}`} />
            <Box className={`${styles['.frame-1931']}`}>
                <Box className={`${styles['.data-process-may-take-up-to-20-mins']}`}>{'Data process may take up to 10 mins'}</Box>
                {/* <Box className={`${styles['.frame-1932']}`}>
                    <EmailIcon className={`${styles['.social-media-email']}`} />
                    <Box className={`${styles['.we-will-notify-you-through-email-once-the-results-are-ready']}`}>
                        {'We will notify you through email once the results are ready'}
                    </Box>
                </Box> */}
            </Box>
        </Box>
    );
};

const ResultError = (error) => {
    const styles = useStyles();
    const theme = useTheme();

    return (
        <Box className={`${styles['.frame-1869']}`}>
            <Box className={`${styles['.frame-1931']}`}>
                <Error
                    sx={{
                        color: theme.palette.grayscale.white,
                        fontSize: '50px',
                        marginBottom: '16px',
                    }}
                />

                <Typography variant='desktop_body' className={`${styles['.data-process-may-take-up-to-20-mins']}`}>
                    {'Error in processing your request. Please contact Amuro.'}
                </Typography>
            </Box>
        </Box>
    );
};

const ResultCompleted = ({
    apiRef,
    rows,
    getColumns,
    columnVisibilityModel,
    highlightedRow,
    setHighlightedRow,
    handleRowClick,
    exportToSpreadsheet,
    starCvHandler,
}) => {
    const theme = useTheme();
    const styles = useStyles();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = React.useState(10);

    React.useEffect(() => {
        if (apiRef.current) {
            apiRef?.current?.setSortModel([
                {
                    field: 'overall_score',
                    sort: 'desc',
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiRef.current]);

    return (
        <Box className={`${styles['.frame-1908']}`}>
            <Box className={`${styles['.frame-1909']}`}>
                <Box
                    className={`${styles['.buttons-navigation']}`}
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <BackIcon className={`${styles['.arrows-left']}`} />
                    <Box className={`${styles['.back']}`}>All Saved Results</Box>
                </Box>
                <Box className={`${styles['.frame-1939']}`}>
                    <FilledButton buttonType='primary' onClick={exportToSpreadsheet}>
                        <Typography variant='desktop_buttonPrimary'>{`Download as Spreadsheet`}</Typography>
                    </FilledButton>
                </Box>
            </Box>
            {rows && (
                <Box className={`${styles['.frame-1911']}`}>
                    {highlightedRow && (
                        <RowDetail row={highlightedRow} onClose={() => setHighlightedRow(null)} starCvHandler={starCvHandler} />
                    )}
                    <Box
                        sx={{
                            width: highlightedRow ? 'calc(100% - 330px) !important' : '100% !important',
                            height: '100%',
                        }}
                    >
                        <DataGrid
                            sx={{
                                borderColor: theme.palette.grayscale.grey3,
                                borderRadius: '8px',
                                background: theme.palette.grayscale.black,
                                '& .MuiDataGrid-virtualScroller': {
                                    scrollbarWidth: 'thin',
                                    scrollbarColor: `${theme.palette.grayscale.grey1} transparent`,
                                    '&::-webkit-scrollbar': {
                                        backgroundColor: theme.palette.grayscale.black,
                                        height: '12px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: theme.palette.grayscale.black,
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: theme.palette.grayscale.grey1,
                                        borderRadius: '500px',
                                        border: `3px solid ${theme.palette.grayscale.black}`,
                                    },
                                    '&::-webkit-scrollbar:vertical': {
                                        backgroundColor: theme.palette.grayscale.black,
                                        width: '12px',
                                    },
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: theme.palette.grayscale.grey4,
                                    borderColor: theme.palette.grayscale.grey4,
                                    borderRadius: '8px 8px 0 0',
                                },

                                '& .MuiCheckbox-root svg': {
                                    color: theme.palette.grayscale.white,
                                },
                                '& .MuiDataGrid-row': {
                                    background: theme.palette.grayscale.black,
                                    '&:hover': {
                                        background: theme.palette.grayscale.grey4,
                                    },
                                },
                                '& .MuiDataGrid-row.Mui-selected': {
                                    background: theme.palette.grayscale.grey4,
                                    '&:hover': {
                                        background: theme.palette.grayscale.grey4,
                                    },
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: `1px solid ${theme.palette.grayscale.grey4}`,
                                },
                                '& .MuiDataGrid-cell:focus-within': {
                                    background: 'none',
                                    outline: `none`,
                                },
                                '& .MuiDataGrid-sortIcon': {
                                    opacity: 1,
                                    color: 'white',
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    opacity: 1,
                                    color: 'white',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: theme.palette.grayscale.grey4,
                                    color: theme.palette.grayscale.white,
                                    borderRadius: '0 0 8px 8px',
                                },
                                '& .MuiTablePagination-selectLabel': {
                                    color: theme.palette.grayscale.white,
                                },
                                '& .MuiTablePagination-displayedRows': {
                                    color: theme.palette.grayscale.white,
                                },
                                '& .MuiInputBase-root': {
                                    color: theme.palette.grayscale.white,
                                    '> svg': {
                                        color: theme.palette.grayscale.white,
                                    },
                                },
                                '& .MuiButtonBase-root': {
                                    color: theme.palette.grayscale.white,
                                },
                            }}
                            apiRef={apiRef}
                            slotProps={{}}
                            rowHeight={62}
                            rows={rows}
                            columns={getColumns()}
                            columnVisibilityModel={columnVisibilityModel}
                            getRowId={(row) => row?.file?.ext_id}
                            onRowClick={handleRowClick}
                            disableSelectionOnClick
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'overall_score', sort: 'desc' }],
                                },
                                // Available in Pro version
                                // pinnedColumns: { left: ['candidate_name_or_id', 'overall_score'] },
                            }}
                            localeText={{
                                noRowsLabel: 'No results found',
                            }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[10, 20, 50]}
                            // checkboxSelection
                            // onSelectionModelChange={handleRowSelect}
                            // selectionModel={selectionModel}
                            // isRowSelectable={(params) => !(params.row.redemption && params.row.redemption.is_redeemded)}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

const RowDetail = ({ row, onClose, starCvHandler }) => {
    const styles = useStyles();

    return (
        <Box className={`${styles['.frame-1910']}`}>
            <Box className={`${styles['.frame-1921']}`}>
                <CloseIcon className={`${styles['.actions-close']}`} onClick={onClose} />
                <Box className={`${styles['.frame-1912']}`}>
                    <Box className={`${styles['.score-container']}`}>
                        <Typography variant='desktop_body' className={`${styles['.score']}`} component='span'>
                            {row.overall_score}
                        </Typography>
                        <Typography variant='desktop_footnote' className={`${styles['.total-score']}`} component='span'>
                            {`/${Object.keys(row.score).length - 1}`}
                        </Typography>
                    </Box>
                    <Box className={`${styles['.name']}`}>
                        <Rating
                            onChange={(e, newValue) => starCvHandler(e, newValue, row.cv_id)}
                            emptyIcon={<EmptyStar style={{ pointerEvents: 'none' }} />}
                            icon={<Star />}
                            value={row.starred ? 1 : 0}
                            max={1}
                        />
                        {isNameValid(row.candidate_name_or_id, row.file?.ext_id)
                            ? row.candidate_name_or_id
                            : row.file?.metadata?.file_original_name}
                    </Box>
                    <FilledButton buttonType='secondary'>
                        <a href={row.file?.file_url} target='_blank' rel='noreferrer'>
                            <Typography variant='desktop_buttonPrimary'>{`View Resume`}</Typography>
                        </a>
                    </FilledButton>
                </Box>
            </Box>
            <Box className={`${styles['.attributes']}`}>
                {Object.keys(row.score).map((key, index) => {
                    if (key !== 'candidate_name_or_id') {
                        return (
                            <Box className={`${styles['.attribute-container']}`} key={key}>
                                <Box className={styles['.attribute-row']}>
                                    {row?.score[key] === 1 ? (
                                        <>
                                            <CheckIcon className={`${styles['.indicators-check']}`} />
                                            <Typography className={`${styles['.check-attribute-name']}`}>{convertJSONKey(key)}</Typography>
                                        </>
                                    ) : (
                                        <>
                                            <DashIcon className={styles['.indicators-dash']} />
                                            <Typography className={`${styles['.dash-attribute-name']}`}>{convertJSONKey(key)}</Typography>
                                        </>
                                    )}
                                </Box>
                                <Box className={`${styles['.frame-1920']}`}>
                                    <Typography className={`${styles['.comment']}`}>
                                        {row.comments[key] ? row.comments[key] : 'No comment'}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    }
                    return null;
                })}
            </Box>
        </Box>
    );
};
