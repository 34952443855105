// React / other libraries
import * as React from 'react';
import shortid from 'shortid';
import { useNavigate } from 'react-router-dom';

// MUI / Component
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useStyles from './styles';

const Navigation = ({}) => {
    const theme = useTheme();
    const styles = useStyles();
    const navigate = useNavigate();
    let routePrefix = '/user';
    if (window.location.pathname.includes('/corp')) {
        routePrefix = '/corp';
    }

    const navOptions = [
        {
            id: shortid.generate(),
            name: 'Screen CVs',
            paths: [
                {
                    path: `${routePrefix}/cv-analysis`,
                },
            ],
        },
        {
            id: shortid.generate(),
            name: 'Result List',
            paths: [
                {
                    path: `${routePrefix}/result`,
                },
            ],
        },
        {
            id: shortid.generate(),
            name: 'Top Up',
            paths: [
                {
                    path: `${routePrefix}/top-up`,
                },
            ],
        },
    ];

    const includePath = (paths) => {
        const currentPath = window.location.pathname;
        let result = false;
        paths.forEach((p) => {
            if (p.notInclude) {
                if (currentPath == p.path) {
                    result = true;
                    return;
                }
            } else {
                if (currentPath.includes(p.path)) {
                    result = true;
                    return;
                }
            }
        });
        return result;
    };

    return (
        <Box className={`${styles['.nav-container']}`}>
            {navOptions.map((navOption) => {
                return (
                    <Box
                        key={navOption.id}
                        className={`${styles['.nav-item']}`}
                        onClick={() => {
                            navigate(navOption.paths[0].path);
                        }}
                    >
                        <Typography
                            variant='desktop_buttonPrimary'
                            className={`${includePath(navOption.paths) ? styles['.active'] : styles['.inactive']}`}
                        >
                            {navOption.name}
                        </Typography>
                    </Box>
                );
            })}
        </Box>
    );
};

export default Navigation;
