import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.sign-up-container': {
        minHeight: '800px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        background: theme.palette.background.dark,
    },
    '.sign-up-form': {
        display: 'flex',
        height: '500px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '40px',
        flexShrink: '0',
        marginTop: '146px',
    },
    '.sign-up-title': {
        marginTop: '24px',
        color: theme.palette.grayscale.grey1,
    },
    '.sign-up-text-field-group': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        flex: '1 0 0',
    },
    '.sign-up-button-group': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
    },
    '.sign-in-instead': {
        cursor: 'pointer',
        color: theme.palette.grayscale.white,
    },
}));

export default useStyles;
