export const convertJSONKey = (key) => {
    let tokens = key.split('_');
    tokens = tokens.map((token) => {
        return token.charAt(0).toUpperCase() + token.slice(1);
    });
    let string = tokens.join(' ');
    return string;
};

export const isNameValid = (name, fileId) => {
    let phrases = ['N/A'];

    if (fileId) {
        phrases.push(fileId);
    }

    let valid = true;

    for (let i = 0; i < phrases.length; i++) {
        if (name.toLowerCase().includes(phrases[i].toLowerCase())) {
            valid = false;
            break;
        }
    }

    return valid;
};

export const windowNavigate = (subDomain, route) => {
    let host = window.location.hostname;
    let hostComponents = host.split('.');
    console.log('hostComponents', hostComponents);
    let currentSubDomain = hostComponents[0];
    let protocol = window.location.protocol;
    let port = window.location.port;

    if (subDomain === '') {
        let url = `${protocol}//${hostComponents[hostComponents.length - 1]}${route}`;
        console.log('url', url);
        window.location.href = `${protocol}//${hostComponents[hostComponents.length - 1]}:${port}${route}`;
    } else if (currentSubDomain !== subDomain) {
        let url = `${protocol}//${subDomain}.${host}${route}`;
        console.log('url', url);
        window.location.href = `${protocol}//${subDomain}.${host}:${port}${route}`;
    } else {
        window.location.href = `${protocol}//${host}:${port}${route}`;
    }
};
