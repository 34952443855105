import * as React from 'react';
import { useTheme, IconButton } from '@mui/material';

const CustomizedIconButton = (props) => {
    const theme = useTheme();

    return (
        <IconButton
            disableTouchRipple
            sx={{
                height: props.height ? props.height : 'auto',
                width: props.height ? props.height : 'auto',
                minWidth: '0 !important',
                '&:hover': {
                    background: props.hoverColor ? props.hoverColor : null,
                },
            }}
            disabled={props.disabled}
            onClick={props.onClick}
            className={props.className}
        >
            {props.children}
        </IconButton>
    );
};

export default CustomizedIconButton;
