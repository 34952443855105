// React / Other libraries
import * as React from 'react';
import { DateTime } from 'luxon';
import * as XLSX from 'sheetjs-style';

// MUI / Components
import useStyles from '../pages/ResultDetail/styles';
import { Box, Rating, Typography, useTheme } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';

// Images
import { ReactComponent as CheckIcon } from '../assets/images/check_icon.svg';
import { ReactComponent as DashIcon } from '../assets/images/dash_icon.svg';
import { ReactComponent as EmptyStar } from '../assets/images/empty_star.svg';
import { ReactComponent as Star } from '../assets/images/star.svg';

// Context / Utils / API
import { UserContext } from '../context/UserProvider';
import { convertJSONKey, isNameValid } from '../utils/utils';
import { getAnalysisRequests, starCv } from '../api/cvAnalysisRequest';

const useResultDetail = (requestId) => {
    // Context
    const { userDetails } = React.useContext(UserContext);

    const theme = useTheme();
    const styles = useStyles();

    const [request, setRequest] = React.useState(null);
    const apiRef = useGridApiRef();
    const [version, setVersion] = React.useState(0);
    const [rows, setRows] = React.useState([]);
    const [dynamicColumnKeys, setDynamicColumnKeys] = React.useState([]);
    const [resultData, setResultData] = React.useState(null);
    const [highlightedRow, setHighlightedRow] = React.useState(null);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const intervalRef = React.useRef(null);

    // Fetch result of cv analysis request
    React.useEffect(() => {
        const fetchResultData = async () => {
            // Fetch for the first time
            if (requestId) {
                let analysisRequest = await getAnalysisRequests(await userDetails.firebaseUser.getIdToken(), {
                    requestId: requestId,
                });

                if (analysisRequest?.status === 'completed') {
                    setRequest({
                        ext_id: analysisRequest?.ext_id,
                        status: analysisRequest?.status,
                    });
                    setResultData(analysisRequest);
                    return;
                }

                if (analysisRequest?.status === 'error') {
                    setRequest({
                        ext_id: analysisRequest?.ext_id,
                        status: analysisRequest?.status,
                    });
                    return;
                }

                if (analysisRequest?.status === 'processing' || analysisRequest?.status === 'pending') {
                    setRequest({
                        ext_id: analysisRequest?.ext_id,
                        status: analysisRequest?.status,
                    });
                }

                // Keep fetching until the status is completed or error
                await new Promise((resolve, reject) => {
                    let trialCount = 0;
                    intervalRef.current = setInterval(async () => {
                        trialCount++;
                        try {
                            let analysisRequest = await getAnalysisRequests(await userDetails.firebaseUser.getIdToken(), {
                                requestId: requestId,
                            });
                            if (analysisRequest) {
                                switch (analysisRequest?.status) {
                                    case 'completed':
                                        setRequest({
                                            ext_id: analysisRequest?.ext_id,
                                            status: analysisRequest?.status,
                                        });
                                        setResultData(analysisRequest);
                                        clearInterval(intervalRef.current);
                                        resolve();
                                        break;
                                    case 'error':
                                        setRequest({
                                            ext_id: analysisRequest?.ext_id,
                                            status: analysisRequest?.status,
                                        });
                                        clearInterval(intervalRef.current);
                                        resolve();

                                        break;
                                    default:
                                        setRequest({
                                            ext_id: analysisRequest?.ext_id,
                                            status: analysisRequest?.status,
                                        });
                                        break;
                                }
                            }
                        } catch (err) {
                            console.log(err);
                            clearInterval(intervalRef.current);
                            reject();
                        }
                    }, 5000);
                });
            }
        };

        try {
            fetchResultData();
        } catch (err) {
            console.log(err);
        }

        return () => {
            // demount
            clearInterval(intervalRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails.firebaseUser]);

    // Convert the fetched data into rows for the data grid
    const getColumns = React.useCallback(() => {
        if (rows.length > 0 && dynamicColumnKeys.length > 0) {
            let columns = [
                {
                    field: 'candidate_name_or_id',
                    width: 200,
                    resizeable: true,
                    sortable: true,
                    renderHeader: (GridColumnHeaderParams) => {
                        return (
                            <Typography variant='desktop_label' color={theme.palette.grayscale.white}>
                                {'Candidate Name / ID'}
                            </Typography>
                        );
                    },
                    renderCell: (cellValues) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <a href={cellValues.row.file?.file_url} target='_blank' rel='noreferrer'>
                                    <Typography
                                        variant='desktop_body'
                                        sx={{ textDecoration: 'underline' }}
                                        color={theme.palette.grayscale.white}
                                    >
                                        {isNameValid(cellValues.row.candidate_name_or_id, cellValues.row?.file?.ext_id)
                                            ? cellValues.row.candidate_name_or_id
                                            : cellValues.row?.file?.metadata?.file_original_name}
                                    </Typography>
                                </a>

                                {cellValues.row?.error && (
                                    <Typography variant='desktop_footnote' color={theme.palette.grayscale.white}>
                                        {'File cannot be processed'}
                                    </Typography>
                                )}
                            </Box>
                        );
                    },
                },
                {
                    field: 'overall_score',
                    width: 150,
                    resizeable: true,
                    sortable: true,
                    renderHeader: (GridColumnHeaderParams) => {
                        return (
                            <Typography variant='desktop_label' color={theme.palette.grayscale.white}>
                                {'Score'}
                            </Typography>
                        );
                    },
                    renderCell: (cellValues) => {
                        return cellValues.row?.error ? (
                            <DashIcon className={styles['.indicators-dash']} />
                        ) : (
                            <Typography variant='desktop_body' color={theme.palette.grayscale.white}>
                                {`${cellValues.row?.overall_score} / ${Object.keys(cellValues.row?.score).length - 1}`}
                            </Typography>
                        );
                    },
                },
            ];
            dynamicColumnKeys.forEach((key) => {
                columns.push({
                    field: key,
                    flex: 1,
                    minWidth: 200,
                    resizeable: true,
                    sortable: true,
                    renderHeader: (GridColumnHeaderParams) => {
                        return (
                            <Typography variant='desktop_label' color={theme.palette.grayscale.white}>
                                {convertJSONKey(key)}
                            </Typography>
                        );
                    },
                    renderCell: (cellValues) => {
                        return cellValues.row?.error || cellValues.row?.score[key] === 0 ? (
                            <DashIcon className={styles['.indicators-dash']} />
                        ) : (
                            <CheckIcon className={`${styles['.indicators-check']}`} />
                        );
                    },
                });
            });

            if (version === 'v2') {
                columns.unshift({
                    field: 'starred',
                    width: 60,
                    resizeable: true,
                    sortable: true,
                    renderHeader: (GridColumnHeaderParams) => {
                        return (
                            <Typography variant='desktop_label' color={theme.palette.grayscale.white}>
                                {''}
                            </Typography>
                        );
                    },
                    renderCell: (cellValues) => {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <Rating
                                    onChange={(e, newValue) => starCvHandler(e, newValue, cellValues.row.cv_id)}
                                    emptyIcon={<EmptyStar style={{ pointerEvents: 'none' }} />}
                                    icon={<Star />}
                                    value={cellValues.row?.starred ? 1 : 0}
                                    max={1}
                                />
                            </Box>
                        );
                    },
                });
            }

            return columns;
        } else {
            return [];
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, dynamicColumnKeys, version]);

    React.useEffect(() => {
        console.log(rows);
    }, [rows]);

    const columnVisibilityModel = React.useMemo(() => {
        if (rows) {
            return {
                // redemtion_link: true,
                // status: true,
                // certificate_index_in_batch: true,
                // file_name: true,
                // certificate_description: true,
                // private_message: certificateRows.some((row) => row.parsed_metadata.private_message),
                // issued_date: true,
                // recipient_name: certificateRows.some((row) => row.parsed_metadata.recipient_name),
                // remarks: certificateRows.some((row) => row.parsed_metadata.remarks),
                // certificate_name: true,
                // redemption_date: true,
            };
        }
    }, [rows]);

    // Process the certificate data when it is fetched
    React.useEffect(() => {
        const processData = async () => {
            if (resultData) {
                // Process the data
                let processedRows = [];
                let candidates = resultData?.response;
                let errorFiles = resultData?.error_files;

                if (candidates) {
                    for (let i = 0; i < candidates.length; i++) {
                        let candidate = structuredClone(candidates[i]);

                        delete candidate?.score['candidate_name_or_id'];
                        delete candidate?.comments['candidate_name_or_id'];

                        // Put the score at the top level for sorting
                        for (let key in candidate.score) {
                            candidate[key] = candidate.score[key];
                        }
                        processedRows.push(candidate);
                    }
                }

                if (errorFiles) {
                    // Insert row for error files
                    for (let i = 0; i < errorFiles.length; i++) {
                        let errorFile = errorFiles[i];
                        let row = {
                            candidate_name_or_id: errorFile?.file?.metadata?.file_original_name,
                            overall_score: 0,
                            score: null,
                            comments: null,
                            file: errorFile?.file,
                            error: errorFile.error,
                        };
                        processedRows.push(row);
                    }
                }

                if (processedRows.length > 0) {
                    setVersion(resultData?.version);
                    setRows(processedRows);
                    setDynamicColumnKeys(Object.keys(processedRows[0].score));
                }
            }
        };
        processData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultData]);

    // Row select handler
    const handleRowSelect = React.useCallback(
        (e) => {
            setSelectionModel(e);
            // setSelectedCerts(filtered);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rows]
    );

    // Row click handler, show the row details
    const handleRowClick = React.useCallback(
        (e) => {
            let filtered = rows.filter((row) => row?.file?.ext_id === e.id);
            let selectedRow = filtered.length > 0 ? filtered[0] : null;
            if (selectedRow?.error) {
                setHighlightedRow(null);
                return;
            }
            setHighlightedRow(selectedRow);
        },
        [rows]
    );

    // What library to import
    const exportToXLSX = React.useCallback(() => {
        if (rows.length > 0 && dynamicColumnKeys.length > 0) {
            let jsons = [];

            // sort row by overall_score and find the top 3 distinct score
            let sortedRows = [...rows].sort((a, b) => {
                return b.overall_score - a.overall_score;
            });

            let distinctScores = [];

            for (let i = 0; i < sortedRows.length; i++) {
                if (distinctScores.length < 3) {
                    if (!distinctScores.includes(sortedRows[i].overall_score) && sortedRows[i].overall_score !== 0) {
                        distinctScores.push(sortedRows[i].overall_score);
                    }
                } else {
                    break;
                }
            }

            sortedRows.forEach((row) => {
                let json = {
                    'Candidate Name / ID': row?.error
                        ? `${row?.file?.metadata?.file_original_name}\nFile cannot be processed`
                        : isNameValid(row.candidate_name_or_id)
                        ? row.candidate_name_or_id
                        : row?.file?.metadata?.file_original_name,
                    Score: row?.error ? '-' : `${row.overall_score}/${Object.keys(row.score).length - 1}`,
                };
                dynamicColumnKeys.forEach((key) => {
                    if (row?.error) {
                        json[convertJSONKey(key)] = `-`;
                    } else {
                        json[convertJSONKey(key)] = `Score: ${row.score[key]}\nComments: ${row.comments[key]}`;
                    }
                });
                jsons.push(json);
            });

            let workbook = XLSX.utils.book_new();
            let worksheet = XLSX.utils.json_to_sheet(jsons);

            // Insert hyperlink to the candidate name
            sortedRows.forEach((row, index) => {
                let cell = worksheet[`A${index + 2}`];

                cell.s = {
                    font: {
                        underline: true,
                    },
                };

                if (row?.file?.file_url && row?.file?.metadata?.file_original_name) {
                    cell.l = {
                        Target: row?.file?.file_url,
                        Tooltip: row?.file?.file_url,
                    };
                }
            });

            for (let i in worksheet) {
                if (typeof worksheet[i] != 'object') continue;
                let cell = XLSX.utils.decode_cell(i);
                let style = {
                    // styling for all cells
                    ...worksheet[i].s,
                    alignment: {
                        vertical: 'bottom',
                        horizontal: 'left',
                        wrapText: true, // any truthy value here
                    },
                    border: {
                        top: {
                            style: 'thin',
                            color: {
                                rgb: 'FF000000',
                            },
                        },
                        bottom: {
                            style: 'thin',
                            color: {
                                rgb: 'FF000000',
                            },
                        },
                        left: {
                            style: 'thin',
                            color: {
                                rgb: 'FF000000',
                            },
                        },
                        right: {
                            style: 'thin',
                            color: {
                                rgb: 'FF000000',
                            },
                        },
                    },
                };

                // Bold text for the first column
                if (cell.c === 0 || cell.c === 1 || cell.r === 0) {
                    if (style.font) {
                        style.font.bold = true;
                    } else {
                        style.font = {
                            bold: true,
                        };
                    }
                }

                // Fill header background to light grey
                if (cell.r === 0) {
                    style.fill = {
                        fgColor: {
                            rgb: 'FFD3D3D3',
                        },
                    };
                }

                // Fill yellow color for row with score which is in distinctScores

                if (cell.r > 0 && distinctScores.includes(sortedRows[cell.r - 1].overall_score)) {
                    style.fill = {
                        fgColor: {
                            rgb: 'FFFFFF00',
                        },
                    };
                }

                worksheet[i].s = style;
            }

            // Set Column Widths dynamic column numbers
            let wscols = [{ wch: 20 }, { wch: 20 }];
            for (let i = 0; i < dynamicColumnKeys.length + 2; i++) {
                wscols.push({ wpx: 150 });
            }

            // Set Row Height
            let wsrows = [{ hpx: 50 }];
            for (let i = 0; i < sortedRows.length; i++) {
                wsrows.push({ hpx: 150 });
            }

            worksheet['!cols'] = wscols;
            worksheet['!rows'] = wsrows;

            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `CV_Analysis_${DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss')}.xlsx`);
        }
    }, [rows, dynamicColumnKeys]);

    const starCvHandler = React.useCallback(
        async (e, newValue, cvId) => {
            if (newValue === 1) {
                await starCv(await userDetails.firebaseUser.getIdToken(), cvId, true);
                setRows((prev) => {
                    let newRows = [...prev];
                    let index = newRows.findIndex((row) => row.cv_id === cvId);
                    newRows[index].starred = true;
                    return newRows;
                });
            } else {
                await starCv(await userDetails.firebaseUser.getIdToken(), cvId, false);
                setRows((prev) => {
                    let newRows = [...prev];
                    let index = newRows.findIndex((row) => row.cv_id === cvId);
                    newRows[index].starred = false;
                    return newRows;
                });
            }
            console.log(rows);
        },
        [userDetails.firebaseUser, rows]
    );

    return {
        request,
        apiRef,
        rows,
        selectionModel,
        setSelectionModel,
        getColumns,
        columnVisibilityModel,
        highlightedRow,
        setHighlightedRow,
        handleRowSelect,
        handleRowClick,
        exportToXLSX,
        starCvHandler,
    };
};

export default useResultDetail;
