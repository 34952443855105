import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    '.nav-container': {
        padding: '0px 24px 0px 24px',
        display: 'flex',
        flexDirection: 'row',
        gap: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        flex: '1',
        position: 'relative',
    },
    '.nav-item': {
        padding: '8px 4px 8px 4px',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexShrink: '0',
        height: '32px',
        position: 'relative',
        cursor: 'pointer',
    },
    '.inactive': {
        color: theme.palette.grayscale.grey1,
        textAlign: 'center',
        position: 'relative',
    },
    '.active': {
        color: theme.palette.grayscale.white,
        textAlign: 'center',
        position: 'relative',
    },
}));

export default useStyles;
