// React / other libraries
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// MUI / Components
import useStyles from './styles';
import { Box, CircularProgress, Typography, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FilledButton from '../../components/Buttons/FilledButton';
import NavBar from '../../components/NavBar/NavBar';
import CustomizedTextField from '../../components/Form/TextField';
import CustomizedSelect from '../../components/Form/Select';

// Images
import { ReactComponent as CrossIcon } from '../../assets/images/cross_icon.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/upload_icon.svg';

// Hooks
import useCreateAnalysisRequest from '../../hooks/useCreateAnalysisRequest';

const CreateAnalysisRequest = ({ cvUploadLimit = 1 }) => {
    const theme = useTheme();
    const styles = useStyles();
    let navigate = useNavigate();

    const {
        loading,
        setLoading,
        createAnalysisRequestForm,
        setCreateAnalysisRequestForm,
        jobDescriptionFileInput,
        CVFileInput,
        jobDescriptionTypes,
        handleSelectJobDescriptionType,
        jobDescriptionUploadHandler,
        CVUploadHandler,
        removeJobDescriptionFileHandler,
        removeCVFileHandler,
        handleSubmit,
    } = useCreateAnalysisRequest({
        cvUploadLimit,
    });

    return (
        <Box className={`${styles['.create-analysis-container']}`}>
            <NavBar />
            {loading ? (
                <Box
                    sx={{
                        height: '500px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={100} color='primary' />
                </Box>
            ) : (
                <Box className={`${styles['.create-analysis-form']}`} component='form' onSubmit={handleSubmit}>
                    <Typography
                        variant='desktop_body'
                        sx={{
                            marginTop: '24px',
                            color: theme.palette.grayscale.grey1,
                        }}
                    >
                        {'Upload Documents'}
                    </Typography>
                    <Box className={`${styles['.form-group']}`}>
                        <Box className={`${styles['.jd-container']}`}>
                            <Typography variant='desktop_label' sx={{ color: theme.palette.grayscale.grey1 }}>
                                {'Job Description*'}
                            </Typography>
                            <CustomizedSelect
                                width='100%'
                                height='40px'
                                value={createAnalysisRequestForm.job_description_type}
                                onChange={handleSelectJobDescriptionType}
                            >
                                {jobDescriptionTypes.map((type, index) => {
                                    return (
                                        <MenuItem key={index} value={type.value}>
                                            <Typography variant='desktop_body'>{`${type.label}`}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </CustomizedSelect>
                            {createAnalysisRequestForm.job_description_type === 'file' ? (
                                <Box className={`${styles['.jd-upload-container']}`}>
                                    <Box className={`${styles['.jd-placeholder']}`}>
                                        <Typography variant='desktop_body' color={theme.palette.grayscale.grey2}>
                                            {'.docx / .txt / .pdf'}
                                        </Typography>
                                        <UploadIcon
                                            className={`${styles['.upload-icon']}`}
                                            onClick={() => {
                                                jobDescriptionFileInput.current.click();
                                            }}
                                        />
                                    </Box>
                                    {createAnalysisRequestForm.job_description_file && (
                                        <Box className={`${styles['.files-container']}`}>
                                            <Box className={`${styles['.file-container']}`}>
                                                <Typography variant='desktop_body' color={theme.palette.grayscale.white}>
                                                    {createAnalysisRequestForm.job_description_file.name}
                                                </Typography>
                                                <CrossIcon
                                                    className={`${styles['.cross-icon']}`}
                                                    onClick={removeJobDescriptionFileHandler}
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                    <input
                                        ref={jobDescriptionFileInput}
                                        value=''
                                        type='file'
                                        style={{ display: 'none' }}
                                        accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document,  application/pdf, text/plain'
                                        onChange={jobDescriptionUploadHandler}
                                    />
                                </Box>
                            ) : (
                                <CustomizedTextField
                                    value={createAnalysisRequestForm.job_description_url}
                                    onChange={(e) => {
                                        setCreateAnalysisRequestForm({
                                            ...createAnalysisRequestForm,
                                            job_description_url: e.target.value,
                                        });
                                    }}
                                    fontSize='14px'
                                    lineHeight='120%'
                                    placeholderTextColor={theme.palette.grayscale.grey1}
                                    placeholder='Website URL'
                                    placeholderFontSize='14px'
                                    placeholderLineHeight='120%'
                                    backgroundColor={theme.palette.grayscale.grey4}
                                    borderColor='transparent'
                                    height='40px'
                                    minWidth='327px'
                                    margin='0px'
                                    width='80%'
                                    maxWidth='400px'
                                    borderRadius='4px'
                                />
                            )}
                        </Box>
                        <Box className={`${styles['.cv-container']}`}>
                            <Typography variant='desktop_label' sx={{ color: theme.palette.grayscale.grey1 }}>
                                {`Resume${cvUploadLimit == 1 ? '' : ' (Multiple Uploads Allowed)'}*`}
                            </Typography>
                            <Box>
                                <Box className={`${styles['.cv-placeholder']}`}>
                                    <Typography variant='desktop_body' color={theme.palette.grayscale.grey2}>
                                        {createAnalysisRequestForm.files.length == 0 ? '.docx / .pdf' : 'Upload More'}
                                    </Typography>
                                    <UploadIcon className={`${styles['.upload-icon']}`} onClick={() => CVFileInput.current.click()} />
                                </Box>
                                {createAnalysisRequestForm.files.length > 0 && (
                                    <Box className={`${styles['.files-container']}`}>
                                        {createAnalysisRequestForm.files.map((file, index) => {
                                            return (
                                                <Box key={index} className={`${styles['.file-container']}`}>
                                                    <Typography variant='desktop_body' color={theme.palette.grayscale.white}>
                                                        {file.name}
                                                    </Typography>

                                                    <CrossIcon
                                                        className={`${styles['.cross-icon']}`}
                                                        onClick={removeCVFileHandler.bind(this, index)}
                                                    />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                )}
                                <input
                                    ref={CVFileInput}
                                    value=''
                                    type='file'
                                    style={{ display: 'none' }}
                                    accept='application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf'
                                    onChange={CVUploadHandler}
                                    multiple
                                />
                            </Box>
                        </Box>
                    </Box>
                    <FilledButton
                        borderRadius='8px'
                        color={theme.palette.theme.nebula}
                        hoverColor={theme.palette.theme.nebula}
                        others={{
                            type: 'submit',
                        }}
                    >
                        <Typography variant='desktop_buttonPrimary' color={theme.palette.grayscale.white}>
                            {'Screen CVs'}
                        </Typography>
                    </FilledButton>
                </Box>
            )}
        </Box>
    );
};

export default CreateAnalysisRequest;
